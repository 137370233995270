import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { Appointment } from 'src/app/models/appointment.model';
import { faTimes, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { EntityTypesService } from 'src/app/services/entity-types.service';
import { AppointmentType } from 'src/app/models/appointment-type.model';
import { SaddleType } from 'src/app/models/saddle-type.model';
import { Fitter } from 'src/app/models/fitter.model';
import { FormGroup, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { Address } from 'src/app/models/address.model';
import { AddressPickerComponent } from 'src/app/components/address-picker/address-picker.component';
import { Location } from '@angular/common';

@Component({
  selector: 'ubc-appointment-page',
  templateUrl: './appointment-page.component.html',
  styleUrls: ['./appointment-page.component.scss']
})
export class AppointmentPageComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private apiService: APIServiceService,
    private entityTypesService: EntityTypesService,
    private router: Router,
    private location: Location) { }

  faTimes = faTimes;
  faCheck = faCheck;
  faEdit = faEdit;

  appointment: Appointment;
  appointmentTypes: AppointmentType[];
  saddleTypes: SaddleType[];
  fitters: Fitter[];
  tables = [];

  appointmentDetailsForm: FormGroup;
  detailsToUpdate: any = {};

  addressCreatorOpen = false;
  editAddressOpen = false;

  newAddress: Address;

  @ViewChild('addressPickerComponent', {}) addressPickerComponent: AddressPickerComponent; // the #addressPicker in the template

  ngOnInit() {
    this.initAppointment();
  }

  initAppointment() {
    this.apiService.appointments.getByID(this.route.snapshot.params.id)
    .then((response) => {
      if (response.success){
        this.appointment = new Appointment().deserialize(response.data);
        return this.entityTypesService.getAllEntities();
      }
    })
    .then((allEntities) => {
      this.appointmentTypes = allEntities.appointmentTypes;
      this.saddleTypes = allEntities.saddleTypes;
      this.fitters = allEntities.fitters;
      this.setupAppointmentDetailsForm();
    })
    .catch((err) => { console.log(err); });
  }

  setupAppointmentDetailsForm(){
    this.appointmentDetailsForm = new FormGroup({
      addressId: new FormControl(this.appointment.address.id),
      typeId : new FormControl(this.appointment.type.id),
      fitterId: new FormControl(this.appointment.fitter.id),
      notes: new FormControl(this.appointment.notes),
      saddleTypeId: new FormControl(this.appointment.saddleType.id),
      budget: new FormControl(this.appointment.budget)
    });
    this.prepareAppintmentForDisplay();
  }


  prepareAppintmentForDisplay() {
    if (!this.appointment) {
      return;
    }


    this.tables = [];

    const apointmentDetails = {
      title: 'Appointment Details',
      editable: false,
      rows: [
        {
          key: 'Address',
          value: this.appointment.address.getFullSummary(),
          field: null,
          editAction: 'editAddress'
        },
        {
          key: 'AddressId',
          value: this.appointment.address.id,
          formControl: this.appointmentDetailsForm.controls.addressId,
          field: 'hidden'
        },
        {
          key : 'Date',
          value : this.appointment.startTime.format('dddd Do MMMM YYYY'),
          field: null
        },
        {
          key : 'Time',
          value : this.appointment.startTime.format('HH:mm') + ' - ' + this.appointment.endTime.format('HH:mm'),
          field: null
        },
        {
          key : 'Type',
          value : this.appointment.type.id,
          field: 'select',
          formControl: this.appointmentDetailsForm.controls.typeId,
          options: this.appointmentTypes.map((type) => {
            return  {
              value : type.id,
              title: type.name
            }
          })
        },
        {
          key : 'Fitter',
          value : this.appointment.fitter.id,
          field: 'select',
          formControl: this.appointmentDetailsForm.controls.fitterId,
          options: this.fitters.map((fitter) => {
            return {
              value: fitter.id,
              title: fitter.name
            }
          })
        },
        {
          key : 'Notes',
          value : this.appointment.notes,
          field: 'textarea',
          formControl: this.appointmentDetailsForm.controls.notes
        }
      ]
    };

    const saddleDetails = {
      title: 'Saddle Details',
      editable: false,
      rows : [
        {
          key: 'Saddle Type',
          value : this.appointment.saddleType.id,
          field: 'select',
          formControl: this.appointmentDetailsForm.controls.saddleTypeId,
          options: this.saddleTypes.map((type) => {
            return {
              value: type.id,
              title: type.name
            };
          })
        },
        {
          key: 'Saddle Budget',
          value : this.appointment.budget,
          field: 'text',
          formControl: this.appointmentDetailsForm.controls.budget
        }
      ]
    }


    const customerDetails = {
      title: 'Customer Details',
      editable: true,
      firstFieldIsTitle: true,
      editClickAction: 'customerEditClicked',
      rows: [
        {
          key : 'Name',
          value: this.appointment.customer.getFullName(),
          field: null
        },{
          key : 'Email',
          value: this.appointment.customer.email,
          field: null
        },{
          key : 'Tel',
          value: this.appointment.customer.tel,
          field: null
        },
        {
          key : 'Notes',
          value: this.appointment.customer.notes,
          field: null
        }
      ]
    }

    const horseDetails = {
      title: 'Horse Details',
      editable: true,
      editClickAction: 'horseEditClicked',
      rows: [
        {
          key : 'Name',
          value: this.appointment.horse.name,
          field: null
        },{
          key : 'Breed',
          value: this.appointment.horse.breed,
          field:  null
        },{
          key : 'Colour',
          value: this.appointment.horse.colour,
          field: null
        },
        {
          key : 'Sex',
          value: this.appointment.horse.sex,
          field:  null
        },
        {
          key : 'Age',
          value: this.appointment.horse.age,
          field:  null
        },
        {
          key : 'Height',
          value: this.appointment.horse.height,
          field:  null
        },
        {
          key : 'Disciplines',
          value: (this.appointment.horse.disciplines) ? this.appointment.horse.disciplines.join('<br>') : ''
        },
        {
          key : 'Is affiliated',
          value: (this.appointment.horse.isAffiliated) ? 'Yes' : 'No',
          field: null
        },
        {
          key : 'Notes',
          value: this.appointment.horse.notes,
          field:  null
        }
      ]
    }


    this.tables.push(customerDetails);
    this.tables.push(apointmentDetails);
    this.tables.push(saddleDetails);

    this.tables.push(horseDetails);

  }

  editClicked(editAction) {
    if (editAction === 'customerEditClicked') {
      this.router.navigateByUrl('/customer/' + this.appointment.customer.id);
    }

    if (editAction === 'horseEditClicked') {
      this.router.navigateByUrl('/horse/' + this.appointment.horse.id + '?appointment=' + this.appointment.id + '&customer=' + this.appointment.customer.id);
    }
  }


  appointmentDetailsSubmitted(){
    Object.keys(this.appointmentDetailsForm.controls).forEach(key => {
      if (this.appointmentDetailsForm.controls[key].touched) {
        this.detailsToUpdate[key] = this.appointmentDetailsForm.controls[key].value;
      }
    });


    if (Object.keys(this.detailsToUpdate).length === 0) {
      Swal.fire({ icon: 'error', title : 'There are no changes to save', timer: 1000 });
      return;
    }

    this.apiService.appointments.patch(this.appointment.id, this.detailsToUpdate)
    .then((response) => {
      if (response.success) {
        Swal.fire({ icon: 'success', title : 'Appoinment details saved' });
        this.initAppointment();
      } else {
        Swal.fire({ icon: 'error', title : 'Error saving appointment' });
      }
    })
    .catch((err) => { console.log(err)});
  }


  // Appointment address

  itemEditClicked(itemAction){
    console.log(itemAction);
    if (itemAction === 'editAddress') {
      this.editAddressClicked();
    }
  }

  editAddressClicked(){
    this.editAddressOpen = true;
  }

  addressChangeCancelClicked(){
    this.editAddressOpen = false;
  }

  addressCreated($event){
    this.addressCreatorOpen = false;
    this.newAddress = null;
    this.addressPickerComponent.getAddresesForCustomer(this.appointment.customer);
  }

  addressCreateCancelled(){
    console.log('Address create cancelled');
  }

  addressPickerCancelled(){
    console.log('Address picker cancelled');
  }

  addressPicked(address){
    console.log('Address picked', address);
    if (!address.id) {
      this.createAddressClicked();
      return;
    }
    this.appointment.address = address;
    this.editAddressOpen = false;
    console.log(this.appointment);
    // this.detailsToUpdate.addressId = this.appointment.address.id;
    this.setupAppointmentDetailsForm();
    this.appointmentDetailsForm.controls.addressId.markAllAsTouched();
    this.appointmentDetailsForm.controls.addressId.markAsDirty();

  }

  createAddressClicked() {
    this.addressCreatorOpen = true;
    this.newAddress = new Address();
    this.appointment.address = this.newAddress;
  }

  appointmentDeleteClicked(){
    this.apiService.appointments.delete(this.appointment.id)
    .then((response) => {
      if (response.success){


        Swal.fire({
          icon: 'success',
          title : 'Appointment deleted successfully'
           })
        .then((dismissed) => {
          this.location.back();
        })
        .catch((err) => { console.log(err) });

      } else {
        Swal.fire({
          icon: 'warning',
          title : 'There was an error whilst deleting this appointment'
           });

      }
    })
    .catch((err) => { console.log(err) });
  }

}
