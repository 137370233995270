<section class="provisional-booking-details-section" *ngIf="bookingDetails">
  <div class="details-card-row name" *ngIf="bookingDetails.name && bookingDetails.email">
    <div class="icon-col"><span class="material-icons">
      person
      </span></div>
    <div class="details-col">
      <h4>{{bookingDetails.name}}</h4>
      {{bookingDetails.email}}
    </div>
  </div>

  <div class="details-card-row" *ngIf="bookingDetails.startTime && bookingDetails.endTime">
    <div class="icon-col">
      <span class="material-icons">
        event
        </span>
    </div>
    <div class="details-col">
      <h4>{{bookingDetails.startTime.format('HH:mma')}} - {{bookingDetails.endTime.format('HH:mma')}}</h4>
      {{bookingDetails.startTime.format('Do MMMM YYYY')}}
    </div>
  </div>

  <div class="details-card-row" *ngIf="!hidePrice">
    <div class="icon-col">
      <span class="material-icons">
        credit_card
        </span>
    </div>
    <div class="details-col">
      <div *ngIf="bookingDetails.depositPaymentId">
        <h4>£{{bookingDetails.price}} Deposit Paid</h4>
      Mileage to be calculated on the day of your appointment
      </div>
      <div *ngIf="!bookingDetails.depositPaymentId">
        <h4>£{{bookingDetails.price}} to pay today</h4>
      Mileage to be calculated on the day of your appointment
      </div>

    </div>
  </div>

</section>
