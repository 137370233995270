<p>Add Address</p>
<form (keydown.enter)="$event.preventDefault()" [formGroup]="createAddressForm" (ngSubmit)="submitAddressClicked()">
  <div class="row" *ngIf="!addressPicked && !manualModeOn">
  <div class="col-xs-12 col-sm-6" >
  <mat-form-field>
        <input matInput [matAutocomplete]="auto" type="text" placeholder="Address search..." autocomplete="off" #addressSearchField/>
    </mat-form-field>
<mat-progress-bar mode="indeterminate" *ngIf="searching"></mat-progress-bar>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplayAddressFromResult" (optionSelected)="addressSearchResultSelected($event)">
      <mat-option *ngFor="let address of addressSearchResults" [value]="address">{{address.getAddressSummary(true)}}</mat-option>
    </mat-autocomplete>
  </div>
  <div class="col-xs-12 col-sm-6">
      <button mat-button color="primary" type="button" (click)="manualModeOn = true">Create address manually</button>
  </div>
  </div>

      <div *ngIf="addressPicked || manualModeOn">
    <mat-form-field>
    <input matInput type="text" formControlName="line1" placeholder="Address line 1"/>
    </mat-form-field>

    <mat-form-field>
    <input matInput type="text" formControlName="line2" placeholder="line 2"/>
    </mat-form-field>

    <mat-form-field>
    <input matInput type="text" formControlName="town" placeholder="town"/>
    </mat-form-field>

    <mat-form-field>
    <input matInput type="text" formControlName="city" placeholder="city"/>
    </mat-form-field>

    <mat-form-field>
    <input matInput type="text" formControlName="postcode" placeholder="postcode"/>
    </mat-form-field>



    <mat-form-field>
    <textarea matInput formControlName="notes" placeholder="Notes"></textarea>
    </mat-form-field>
    <div class="button-row">
        <button mat-raised-button color="warning" type="button" (click)="cancelClicked()">Cancel</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="createAddressForm.invalid">Save Address</button>
    </div>
</div>
  </form>
