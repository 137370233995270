import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { APIResponse } from 'src/app/models/api-response.model';
import {timer, Observable} from 'rxjs';
import {map, switchMap, first} from 'rxjs/operators';
import { debounce } from 'rxjs/operators';


@Component({
  selector: 'ubc-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit {

  constructor(private apiService: APIServiceService) { }
  createUserForm: FormGroup;

  ngOnInit() {
    this.configCreateUserForm();
  }

  configCreateUserForm() {
    this.createUserForm = new FormGroup({
      firstName : new FormControl(null, Validators.required),
      lastName : new FormControl(null, Validators.required),
      email : new FormControl(null, [Validators.required, Validators.email], [this.checkEmailAvailability.bind(this)])
    });
  }

  createUserSubmit() {
    const user: User = new User().deserialize(
      {
        firstName : this.createUserForm.value.firstName,
        lastName : this.createUserForm.value.lastName,
        email : this.createUserForm.value.email
      }
    );
    this.apiService.user.create(user)
    .then((response: APIResponse) => {
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
  }


  checkEmailAvailability(control: FormControl): Observable<any> {
    return control.valueChanges.pipe(
      debounce(() => timer(500)),
      first(),
      switchMap(() => {
        return this.apiService.user.isEmailtaken(this.createUserForm.get('email').value)
        .pipe(
          map((
            (response) => {
              if(response.data.isAvailable) {
                console.log('EMAIL IS AVAILABLE')
                return null;
              }
              console.log('EMAL IS TAKEN');
              return { emailIsInUse: true };
            }
            )
        ));
      })
    );
  }





}
