<form *ngIf="appointmentDetailsForm" [formGroup]="appointmentDetailsForm" (ngSubmit)="appointmentDetailsSubmitted()">

  <div class="column-form-section">
    <div class="form-col-left">
      <h6>Fitter</h6>
    </div>
    <div class="form-col-right">
        <mat-form-field appearance="outline">
            <mat-label>Fitter</mat-label>
            <mat-select formControlName="fitter">
              <mat-option *ngFor="let fitter of fitters" [value]="fitter">
                  {{ fitter.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
    </div>

  </div>

  <div class="column-form-section">
      <div class="form-col-left">
        <h6>Appointment Type</h6>
      </div>
      <div class="form-col-right">
          <mat-form-field appearance="outline">
              <mat-label>Appointment Type</mat-label>
              <mat-select formControlName="type">
                <mat-option *ngFor="let type of appointmentTypes" [value]="type">
                    {{ type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-checkbox *ngIf="refitIsSelected()" formControlName="newSaddleInterest">May the customer be interested in a replacement saddle?</mat-checkbox>

            <mat-form-field appearance="outline" *ngIf="newSaddleIsSelected()">
                <mat-label>Saddle Type</mat-label>
                <mat-select formControlName="saddleType">
                  <mat-option *ngFor="let type of saddleTypes" [value]="type">
                      {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
      </div>

    </div>

    <div class="column-form-section">
        <div class="form-col-left">
          <h6>Appointment Details</h6>
        </div>
        <div class="form-col-right">
            <mat-form-field appearance="outline" *ngIf="newSaddleIsSelected()">
                <span matPrefix>£&nbsp;</span>
              <input matInput type="number"   formControlName="budget" placeholder="Budget" />

              </mat-form-field>
          <mat-form-field appearance="outline">
                <textarea matInput formControlName="notes" rows="5" placeholder="Appointment notes"></textarea>

              </mat-form-field>
        </div>

      </div>







          <!--
            <label>Start: </label>
          <input type="text" formControlName="start" />
          <br>
          <label>End: </label>
          <input type="text" formControlName="end"/>
          <br>
          -->







  <div class="button-row">
    <div>&nbsp;</div>
      <button mat-raised-button color="primary" type="submit" [disabled]="appointmentDetailsForm.invalid">Create appointment</button>
  </div>
</form>
