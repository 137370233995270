import { Address } from './address.model';
import { IDeserializable } from './deserializable.model';
import { Horse } from './horse.model';

export class Customer implements IDeserializable {

  id: number;
  firstName: string;
  lastName: string;
  email: string;
  tel: string;
  billingAddress1: string;
  billingAddress2: string;
  billingTown: string;
  billingCity: string;
  billingPostcode: string;
  notes: string;
  horses: Horse[];
  addresses?: Address[]
  appointmentsCount?: number;

  public getFullName() {
    if(!this.lastName) {
      return this.firstName;
    }
    return this.firstName + ' ' + this.lastName;
  }

  public getAddressSummary(singleLine?: boolean) {
    const joiner = (singleLine) ? ', ' : '<br>';
    let out = (this.billingAddress1) ? this.billingAddress1 : '';
    out += (this.billingTown) ? joiner + this.billingTown : '';
    return out;
  }

  public getFullSummary(singleLine?: boolean) {
    const joiner = (singleLine) ? ', ' : '<br>';
    let out = (this.billingAddress1) ? this.billingAddress1 : '';
    out += (this.billingAddress2) ? joiner + this.billingAddress2  : '';
    out += (this.billingTown) ? joiner + this.billingTown : '';
    out += (this.billingCity) ? joiner + this.billingCity : '';
    out += (this.billingPostcode) ? joiner + this.billingPostcode : '';
    return out;
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
