import { Component, OnInit } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { environment }  from '../../../environments/environment';
import firebase from 'firebase';
import { Router } from '@angular/router';
import { APIServiceService } from 'src/app/api/apiservice.service';

@Component({
  selector: 'ubc-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.scss']
})
export class CustomerLoginComponent implements OnInit {

  email: string = null;
  loginEmailSent = false;
  emailNotRecognized = false;
  constructor(public auth: AngularFireAuth, private router: Router, private apiService: APIServiceService) { }

  ngOnInit(): void {
    this.checkForSigninAttempt();



  }

  async checkForSigninAttempt(): Promise<any>{
    const isSignInAttempt = await this.auth.isSignInWithEmailLink(window.location.href);
    if(isSignInAttempt){
      console.log('Is signin attempt');
      const email = window.localStorage.getItem('emailForSignIn');
      if(!email){
        console.log('Saved email not found')

        return;
      }

      this.signInWithEmailLink(email);
    }
  }

  async sendLoginEmail(){
    if(!this.email){
      return;
    }

    const userExists = await this.apiService.me.isEmailtaken(this.email)
    if(!userExists.success || userExists.data.isAvailable){
      console.log('Email address is not recognized');
      setTimeout(()=>{
        this.emailNotRecognized = false;
      }, 3000)
      this.emailNotRecognized = true;
      return;
    }
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: environment.siteUrl + '/login',
      // This must be true.
      handleCodeInApp: true
    };

    this.auth.sendSignInLinkToEmail(this.email, actionCodeSettings)
    .then(()=>{
      window.localStorage.setItem('emailForSignIn', this.email);
      this.loginEmailSent = true;
    })
    .catch((err)=>{
      console.log(err)
    })

  }

  signInWithEmailLink(email){
    this.auth.signInWithEmailLink(email, window.location.href)
    .then((result)=>{
      window.localStorage.removeItem('emailForSignIn');
      this.router.navigateByUrl('my-account')
    })
    .catch((err)=>{
      console.log('Login Error ', err);
    })
  }

}
