<div class="page">
  <div class="customer-details">

    <mat-tab-group>
      <mat-tab label="Customer Details">
        <ubc-details-table-form
        *ngIf="customerDetailsForm"
        [formGroup]="customerDetailsForm"
        [tables]="tables"
        (editClickedEmitter)="editClicked($event)"
        (formSubmittedEmitter)="customerDetailsSubmitted()"></ubc-details-table-form>

</mat-tab>


<mat-tab *ngIf="customer" label="Customer's Horses ({{(customer && customer.horses) ? customer.horses.length : 0}})">

  <div class="flex-row horse-details" [ngClass]="{'no-horses' : !customer.horses || customer.horses.length == 0}">

    <div class="horses-table-wrap">
      <h3 *ngIf="customer && customer.horses.length > 0">{{customer.getFullName()}}'s horses</h3>
      <ubc-horses-table
      [buttonText]="'View Details'"
      [customer]="customer"
      [customersHorsesDataSource]="customersHorsesDataSource"
      (horseButtonClicked)="viewHorseClicked($event)"
      (horseRowClicked)="viewHorseClicked($event)"
      ></ubc-horses-table>
</div>
<div *ngIf="customer" class="horse-creator-wrap" [ngClass]="{'no-horses' : !customer.horses || customer.horses.length == 0}">
  <ubc-create-horse [customer]="customer" (horseCreated)="newHorseCreated($event)" (cancelled)="newHorseCancelled()"></ubc-create-horse>
</div>
  </div>
</mat-tab>

<mat-tab *ngIf="customer" label="Customer's Associated Addresses">
  <div class="address-admin-wrap">
<ubc-address-picker
*ngIf="!addressCreatorOpen"
(cancelled)="null"
(addressPicked)="addressPicked($event)"
[customer]="customer"
#addressPickerComponent></ubc-address-picker>

<ubc-address-creator
*ngIf="addressCreatorOpen"
[customer]="customer"
(addressCreated)="addressCreated($event)"
(cancelled)="addressCreateCancelled()"
></ubc-address-creator>
</div>

</mat-tab>

  </mat-tab-group>
  </div>

