import { AfterViewInit, Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { PaymentIntentType } from 'src/app/api/payments-endpoint';
import { Customer } from 'src/app/models/customer.model';
import { environment } from 'src/environments/environment';


declare var Stripe;
declare var elements: any;

@Component({
  selector: 'ubc-card-payment',
  templateUrl: './card-payment.component.html',
  styleUrls: ['./card-payment.component.scss']
})
export class CardPaymentComponent implements OnInit, AfterViewInit {
  stripe = Stripe(environment.stripePublishableKey);
  elements = this.stripe.elements();
  cardDetailsForm: FormGroup;
  @Input() customer: Customer;

  @Output() paymentWasSuccessfull = new EventEmitter();
  @Output() paymentFailed = new EventEmitter();
  @Output() paymentDidInit = new EventEmitter();

  @Output() preSubmitPayment = new EventEmitter();

  paymentSuccess = false;

  checkboxChecked = false;

  paymentIntentSecret = null;

  card;

  constructor(
    private apiService: APIServiceService) { }


  ngOnInit() {



  }

  ngAfterViewInit(){
    console.log('PAYMENT INIT')

    this.paymentDidInit.emit(null);


  }

  initializePaymentForm(intenttype: PaymentIntentType, hashedAppointmentId: string){
    if(this.card){
      console.log('Already got payment element')
      return;
    }
    this.getPaymentIntent(intenttype, hashedAppointmentId)
    .then((paymentIntentSecret) => {
      this.paymentIntentSecret = paymentIntentSecret;
      const style = {
        base: {
          color: '#32325d',
          fontSmoothing: 'antialiased',
          fontSize: '18px',
          '::placeholder': {
            color: '#32325d'
          }
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };
      // console.log('Creating card element', elements);

      this.card = this.elements.create('card', { style });
      // Stripe injects an iframe into the DOM
      this.card.mount('#card-element');
      this.card.on('change', (event) => {
        // Disable the Pay button if there are no card details in the Element
        document.querySelector('button').disabled = event.empty;
        document.querySelector('#card-error').textContent = event.error ? event.error.message : '';
      });

      const form = document.getElementById('payment-form');
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        // Complete payment when the submit button is clicked
        this.beforeSubmitPayment(this.stripe, this.card, this.paymentIntentSecret);
      });

    })
    .catch((err) => {
      console.log('Error when setting up strip form', err);
      console.log(err); });
  }

  getPaymentIntent(intenttype: PaymentIntentType, hashedAppointmentId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.payments.createIntent(intenttype, hashedAppointmentId)
      .then((response) => {
          resolve(response.data.paymentIntent);
      })
      .catch((err) => { console.log(err) });
    });
  }

  checkboxChanged(event){
     this.checkboxChecked = event.detail.checked;

  }



  requestPaymentIntent(intentType: PaymentIntentType, hashedAppointmentId: string){
    console.log('Requesting intent in card payment')
    this.initializePaymentForm(intentType, hashedAppointmentId);
  }

  beforeSubmitPayment(stripeEl, card, secret){
    this.preSubmitPayment.emit((submit: boolean)=>{
        console.log('Pre submit completed');
        if(submit){
          this.submitPayment(stripeEl, card, secret)
        }
    });
  }

  submitPayment(stripeEl, card, secret){

    this.loading(true);



    stripeEl
    .confirmCardPayment(secret, {
      payment_method: {
        card
      }
    })
    .then((result) => {
      if (result.error) {
        // Show error to your customer
        this.showError(result.error.message);
      } else {
        // The payment succeeded!
        this.orderComplete(result.paymentIntent.id);
      }
    });
  }

  // Shows a success message when the payment is complete
orderComplete(paymentIntentId) {
  this.paymentSuccess = true;
  this.loading(false);

  // document
  //   .querySelector('.result-message a')
  //   .setAttribute(
  //     'href',
  //     'https://dashboard.stripe.com/test/payments/' + paymentIntentId
  //   );
  // document.querySelector('.result-message').classList.remove('hidden');
  // document.querySelector('button').disabled = true;

  setTimeout(() => {
    this.paymentWasSuccessfull.emit(null);

  }, 2000);

};
// Show the customer the error from Stripe if their card fails to charge
showError(errorMsgText) {
  this.loading(false);
  const errorMsg = document.querySelector('#card-error');
  errorMsg.textContent = errorMsgText;
  setTimeout(() => {
    errorMsg.textContent = '';
  }, 4000);
};


loading(isLoading) {
  if (isLoading) {
    // Disable the button and show a spinner
    document.querySelector('button').disabled = true;
    document.querySelector('#spinner').classList.remove('hidden');
    document.querySelector('#button-text').classList.add('hidden');
  } else {
    document.querySelector('button').disabled = false;
    document.querySelector('#spinner').classList.add('hidden');
    document.querySelector('#button-text').classList.remove('hidden');
  }
};




  // generateStripeToken(): Promise<string>{
  //   return new Promise((resolve, reject) => {
  //     const cardNumber = this.cardDetailsForm.get('cardNumber').value;
  //     const expiryComponents = this.cardDetailsForm.get('expiryDate').value.split('-');
  //     const securityCode = this.cardDetailsForm.get('securityCode').value;

  //     const expiryYear = expiryComponents[0];
  //     const expiryMonth = expiryComponents[1];


  //     const card = {
  //       number: cardNumber,
  //       expMonth: expiryMonth,
  //       expYear: expiryYear,
  //       cvc: securityCode
  //     };

  //     console.log(card);

  //     if(!this.cardDetailsForm.valid){
  //       reject('Form invalid');
  //     }

  //     if (!this.platform.is('cordova') ){
  //       resolve('simulation_token');
  //       return;
  //     }



  //     // const key = 'pk_live_giF1w3cvsEuCsqwtMpF2fE6L';
  //     const key = 'pk_test_9HEE4dAjkj90MSmsTBepnyph';
  //     this.stripe.setPublishableKey(key);

  //     this.stripe.createCardToken(card)
  //         .then(token => {
  //           resolve(token.id);
  //         })
  //         .catch(error => console.error(error));

  //   });

  // }

  // this.generateStripeToken()
    // .then((token) => {
    //     this.apiService.customer.create(newCustomer, token, this.constructPin())
    //   .then((response) => {
    //     console.log(response);
    //     if (response.success && response.data === 'payment_successfull') {
    //       Swal.fire('Registration Success','Thank you for registering, we have emailed you instructions on how to complete your application for your new Vcard');
    //       return this.verificationService.setDeviceVerificationStatus({status : this.verificationService.REGISTRATION_PAID })
    //     }

    //   })
    //   .then(() => {
    //     console.log('Custoemr has paid and is awaiting KYC');
    //   })
    //   .catch((err) => { console.log(err) });
    // })
    // .catch((err) => { console.log(err) });

}
