<h3>Create horse for {{customer.getFullName()}}</h3>
<form [formGroup]="createHorseForm" (ngSubmit)="createHorseSubmitted()">
    <mat-form-field>
        <input matInput type="text" formControlName="name" placeholder="Horse name"/>
    </mat-form-field>

    <mat-form-field>
        <input matInput type="number" formControlName="height" placeholder="Height"/>
    </mat-form-field>

    <mat-form-field>
        <input matInput type="number" formControlName="age" placeholder="Age"/>
    </mat-form-field>

    <mat-form-field>
        <input matInput type="text" formControlName="breed" placeholder="Breed"/>
    </mat-form-field>

    <mat-form-field>
        <input matInput type="text" formControlName="sex" placeholder="Sex"/>
    </mat-form-field>

    <mat-form-field>
        <input matInput type="text" formControlName="colour" placeholder="Colour"/>
    </mat-form-field>


    <div formGroupName="disciplines">


      <mat-checkbox formControlName="ALLROUNDER">All Rounder</mat-checkbox>&nbsp;
      <mat-checkbox formControlName="DRESSAGE">Dressage</mat-checkbox>&nbsp;
      <mat-checkbox formControlName="JUMPING">Jumping</mat-checkbox>&nbsp;
      <mat-checkbox formControlName="EVENTING">Eventing</mat-checkbox>&nbsp;
      <mat-checkbox formControlName="HACKING">Hacking</mat-checkbox>&nbsp;
      <mat-checkbox formControlName="ENDURANCE">Endurance</mat-checkbox>&nbsp;
      <mat-checkbox formControlName="SHOWING">Showing</mat-checkbox>&nbsp;



    </div>

    <br>
    <mat-checkbox formControlName="isAffiliated">Affiliated?</mat-checkbox>
    <br>



    <div class="button-row">
        <button type="submit" mat-raised-button color="primary" [disabled]="createHorseForm.invalid">Add Horse</button>
    </div>

</form>


