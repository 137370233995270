<div id="background"></div>

<div id="sign-in-panel">
  <div class="sign-in-inner">
      <img id="logo" src="assets/images/logo.png" class="img-responsive" />

      <h1>Log In</h1>

      <div class="sign-in-btn" (click)="signInWithGoogle()">
        <div class="inner"></div>
          Sign in with Google
      </div>

      <div class="help-block">{{ signInErrorMessage }}</div>
  </div>
</div>


<!-- <div *ngIf="!user"><button (click)="signInWithGoogle()">Sign In With Google Account</button></div>
<a routerLink="/home">
<button>HOME</button>
</a> -->

