import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Address } from 'src/app/models/address.model';
import { Customer } from 'src/app/models/customer.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AddressesServiceService } from 'src/app/services/addresses-service.service';
import { fromEvent } from 'rxjs';
import { map, debounceTime, filter, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ubc-address-creator',
  templateUrl: './address-creator.component.html',
  styleUrls: ['./address-creator.component.scss']
})
export class AddressCreatorComponent implements OnInit, AfterViewInit {

  @Input() customer: Customer;
  @Output() addressCreated = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  constructor(private apiService: APIServiceService, private addressService: AddressesServiceService) { }
  createAddressForm: FormGroup;

  addressSearchField: ElementRef;
  addressSearchResults: Address[];
  postcodeSearchValue: string;

  addressPicked: boolean;
  manualModeOn: boolean;

  searching = false;

  @ViewChild('addressSearchField', {}) set element(addressSearchField: ElementRef) {
    this.addressSearchField = addressSearchField;
    if (!this.addressSearchField) {
      return;
    }

    fromEvent(this.addressSearchField.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 1)
      , debounceTime(800)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      if (!this.addressService.validatePostcode(text) || this.searching) {
        return;
      }

      this.searching = true;
      // this.apiService.addresses.lookupPostcode(text)
      this.apiService.addresses.searchAddress(text)
      .subscribe((res) => {
        this.searching = false;
        if (res.success && res.data.results.length > 0) {
          this.addressSearchResults = res.data.results.map(a => new Address().deserialize(a));
          console.log(this.addressSearchResults);
        } else {
          this.addressSearchResults = [];
        }
      });
    });
 }

  ngOnInit() {
    const controls = {
      line1 : new FormControl(null),
      line2 : new FormControl(null),
      town : new FormControl(null),
      city : new FormControl(null),
      postcode : new FormControl(null),
      country : new FormControl(null),
      notes : new FormControl(null)
    }
    this.createAddressForm = new FormGroup(controls);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.addressSearchField.nativeElement.focus();
    }, 0)

  }

  submitAddressClicked() {
    this.createAddressForm.value.customerId = this.customer.id;
    this.apiService.addresses.create(new Address().deserialize(this.createAddressForm.value))
    .then((response) => {
    console.log('Created address', response.data);
    const newAddress = new Address().deserialize(response.data);
    this.addressCreated.emit(newAddress);

    })
    .catch((err) => { console.log(err) });
  }

  getDisplayAddressFromResult(address?: any) {
    return address ? address.formatted_address : undefined;
  }

  postcodeSearchClicked() {
    this.apiService.addresses.lookupPostcode(this.postcodeSearchValue)
      .subscribe((res) => {
        if (res.success && res.data.results_count > 0) {
          this.addressSearchResults = res.data.results.map(a => new Address().deserialize(a));
          console.log(this.addressSearchResults);
        } else {
          this.addressSearchResults = [];
        }
      });
  }

  addressSearchResultSelected(option: MatAutocompleteSelectedEvent) {
    this.addressPicked = true;
    this.populateAddressWithSearchresult(option.option.value);
  }

  populateAddressWithSearchresult(addressResult: Address) {
    this.createAddressForm
    .patchValue(
      { line1 : addressResult.line1,
        line2 : addressResult.line2,
        town: addressResult.town,
        city: addressResult.city,
        postcode: addressResult.postcode,
        country : addressResult.country
      }
    );
  }

  cancelClicked() {
    this.cancelled.emit(null);
  }

}
