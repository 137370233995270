import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Appointment } from 'src/app/models/appointment.model';
import * as moment from 'moment';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
// import rrulePlugin from '@fullcalendar/rrule';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput, Calendar } from '@fullcalendar/core';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { Customer } from 'src/app/models/customer.model';
import { APIResponse } from 'src/app/models/api-response.model';
import { Router } from '@angular/router';
import { AppointmentType } from 'src/app/models/appointment-type.model';


@Component({
  selector: 'ubc-calendar-page',
  templateUrl: './calendar-page.component.html',
  styleUrls: ['./calendar-page.component.scss']
})
export class CalendarPageComponent implements OnInit, AfterViewInit {
  startTime = '2020-03-07 13:00:00';
  endTime = '2020-03-07 15:00:00';
  constructor(private apiService: APIServiceService, private router: Router) { }
  appointment: Appointment = null;
  @ViewChild('calendar', {}) private calendarComponent: FullCalendarComponent; // the #calendar in the template

  calenderPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
  private calendarApi: Calendar;
  calendarEvents: EventInput[] = [];
  additionalCalendarId: string; // if set this will be the ID of the additional google calendar events to show
  calendarRatio = 1.3;

  businessHours = {
    // days of week. an array of zero-based day of week integers (0=Sunday)
    daysOfWeek: [ 1, 2, 3, 4, 5, 6 ], // Monday - Thursday
    startTime: '08:00', // a start time (10am in this example)
    endTime: '18:00', // an end time (6pm in this example)
  }

  ngOnInit() {
    // this.appointment = new Appointment();
    // this.appointment.startTime = moment();
    // this.appointment.endTime = moment().add(1, 'hours');

    console.log('WIDTH: ', window.screen.availWidth);
    console.log('HEIGHT: ', window.screen.availHeight);
    console.log('RATIO: ', window.screen.availWidth / window.screen.availHeight);
  }



  ngAfterViewInit(){
    if(!this.calendarComponent){
      return;
    }
    this.calendarApi = this.calendarComponent.getApi();

    this.calendarRatio = window.screen.availWidth / window.screen.availHeight;

    this.apiService.user.getMeta('gcal_id')
    .then((response) => {
      if(response.success && response.data.meta_key === 'gcal_id') {
        this.additionalCalendarId = response.data.meta_value;
      }
      this.viewWasRendered();
    })
    .catch((err) => {
      this.viewWasRendered();
      console.log(err)
    });



  }

  calendarEventRender($event){
   // $event.timeFormat
  }

  viewWasRendered() {
    if(!this.calendarApi){
      return;
    }
    this.calendarEvents = [];
    this.populateEventsToCalendar();
  }

  populateEventsToCalendar(){
    this.apiService.appointments.getWithinDates(
      moment(this.calendarApi.view.currentStart).subtract(14, 'days').format('YYYY-MM-DD'),
      moment(this.calendarApi.view.currentEnd).add(14, 'days').format('YYYY-MM-DD'),
      false)
    .then((response) => {

      if(!response.success) {
        return;
      }
      this.calendarEvents = [];
      console.log(response)
      if(response.data.results && response.data.results.length === 0){
        return
      }
      response.data.forEach(eventObject => {
        const customer = new Customer().deserialize(eventObject.customer);

        var classString = 'fitter-' + eventObject.fitter.id;
        if (eventObject.type.id === 13){
          classString += ' provisional';
        }

        if(customer.appointmentsCount === 1){
          classString += ' first-appointment';
        }

        if(eventObject.address.postcode === 'LE9 7EF'){
          classString += ' at-bullpit'
        }

        console.log('parsing event ', eventObject)

        const appointmentType = new AppointmentType(eventObject.type.id, eventObject.type.name);

        this.calendarEvents.push({
          id: eventObject.id,
          title: customer.getFullName() + " " + appointmentType.abbreviation + " (" + customer.appointmentsCount + ")",
          date: eventObject.startTime,
          end: eventObject.endTime,
          allDay: false,
          durationEditable: true,
          editable: true,
          timeFormat: {
            hour: 'numeric',
            minute: '2-digit',
            meridiem: false
          },
          className: classString

        });
      });
      return this.getUserGoogleEvents();

    })
    .then((gcalEvents) => {
      console.log('gcalEvents ', gcalEvents)
      if(!gcalEvents || !gcalEvents.data || !gcalEvents.data.items){
        return;
      }
      gcalEvents.data.items.forEach(googleEvent => {
        let startMoment: string;
        let endMoment: string;
        let allDay = false;
        if (googleEvent.end.date) {
          startMoment = moment(googleEvent.start.date).toISOString();
          endMoment = moment(googleEvent.end.date).toISOString();
          allDay = true;
        } else {
          startMoment = moment(googleEvent.start.dateTime).toISOString();
          endMoment = moment(googleEvent.end.dateTime).toISOString();
        }

        this.calendarEvents.push({
          id: googleEvent.id,
          title: googleEvent.summary,
          date: startMoment,
          end: endMoment,
          allDay,
          durationEditable: false,
          editable: false,
          backgroundColor: 'rgb(11, 128, 67)',
          textColor: '#FFF',
          timeFormat: {
            hour: 'numeric',
            minute: '2-digit',
            meridiem: false
          },
          className: 'gcal-event',
          rrule: (googleEvent.recurrence) ? googleEvent.recurrence : null
        });
      });



    })
    .catch((err) => { console.log(err) });
  }

  getUserGoogleEvents(): Promise<any>{
    console.log("Get google dates ")
    return new Promise((resolve, reject) => {
      console.log(moment(this.calendarApi.view.currentStart).subtract(3, 'days').format('YYYY-MM-DD'));
      console.log(moment(this.calendarApi.view.currentEnd).add(3, 'days').format('YYYY-MM-DD'));
      if (!this.additionalCalendarId){
        resolve([]);
        return;
      }
      this.apiService.user.getGCalEventsWithinDates(moment(this.calendarApi.view.currentStart).subtract(7, 'days').format('YYYY-MM-DD'),moment(this.calendarApi.view.currentEnd).add(7, 'days').format('YYYY-MM-DD'))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => { console.log(err) });
    });
  }

  startCreateAppointmentClicked() {
    this.appointment = new Appointment();
    this.appointment.startTime = moment(this.startTime);
    this.appointment.endTime = moment(this.endTime);
  }
  cancelCreateAppointmentClicked() {
    this.appointment = null;
  }

  handleDateClick($event) {
    console.log($event);
    if ($event.view.type === 'dayGridMonth' || $event.view.type === 'timeGridWeek') {
      // A month day was clicked, so show the day
      this.calendarApi.changeView('timeGridDay', $event.date);
    }

    if ($event.view.type === 'timeGridDay'){
      const clickedTime = moment($event.date);
      this.appointment = new Appointment();
      this.appointment.startTime = clickedTime;
      this.appointment.endTime = clickedTime.clone().add(1, 'hours');
    }


  }

  handleEventClick($event) {
    if($event.event.classNames.includes('gcal-event')){
      return;
    }
    this.router.navigateByUrl('/appointment/' + $event.event.id);
  }

  eventWasResized($event) {
    console.log($event);
    this.apiService.appointments.updateStartEndTimes(
      $event.event.id,
      moment($event.event.start).format('YYYY-MM-DD HH:mm:ss'),
      moment($event.event.end).format('YYYY-MM-DD HH:mm:ss'))
      .then((response) => {
        console.log('Event drag completed', response);
      })
      .catch((err) => { console.log(err) });
  }

  eventWasDragged($event) {

    this.apiService.appointments.updateStartEndTimes(
      $event.event.id,
      moment($event.event.start).format('YYYY-MM-DD HH:mm:ss'),
      moment($event.event.end).format('YYYY-MM-DD HH:mm:ss'))
      .then((response) => {
        console.log('Event drag completed', response);
      })
      .catch((err) => { console.log(err) });
  }

  appointmentWasCreated() {
    this.appointment = null;
    this.populateEventsToCalendar();
  }

  swipedLeft($event){
    this.calendarApi.next();
  }

  swipedRight(){
    this.calendarApi.prev();
  }


  // @HostListener('window:scroll', ['$event']) onScrollEvent($event){
  //     console.log('window.pageYOffset ', window.pageYOffset);
  //     console.log('window.scrollY ', window.scrollY);
  //     console.log('document.body.offsetHeight ', document.body.offsetHeight);
  //   if (window.pageYOffset > (window.outerHeight * 0.5)) {
  //     console.log('At bottom!!');
  //     window.scrollTo(0,0);
  //     this.calendarApi.next();
  // }
  // }

}
