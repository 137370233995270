import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { APIResponse } from 'src/app/models/api-response.model';
import { HorseDiscipline } from 'src/app/models/horse-disciplines.enum';
import { Customer } from 'src/app/models/customer.model';
import { Horse } from 'src/app/models/horse.model';
import { APIServiceService } from 'src/app/api/apiservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'ubc-create-horse',
  templateUrl: './create-horse.component.html',
  styleUrls: ['./create-horse.component.scss']
})
export class CreateHorseComponent implements OnInit {

  @Input() customer: Customer;
  @Output() horseCreated = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  constructor(private apiService: APIServiceService) { }
  createHorseForm: FormGroup;
  ngOnInit() {
    const disciplineControls = {};
    for (const discipline in HorseDiscipline) {
      if (!discipline) { continue; }
      disciplineControls[discipline] = new FormControl(null);
   }

    const controls = {
      name : new FormControl(null),
      height : new FormControl(null),
      age : new FormControl(null),
      breed : new FormControl(null),
      sex : new FormControl(null),
      isAffiliated : new FormControl(null),
      colour : new FormControl(null),
      disciplines : new FormGroup(disciplineControls)

    };
    this.createHorseForm = new FormGroup(controls);
  }

  getDisciplines(): any {
    const out = [];
    for (const discipline in HorseDiscipline) {
      if (!discipline) { continue; }
      out.push({ name : discipline, title :  HorseDiscipline[discipline]});
   }
    return out;
  }

  createHorseSubmitted() {
    const fValues = {... this.createHorseForm.value};
    fValues.disciplines = Object.keys(this.createHorseForm.value.disciplines).filter((key) => {
      if( this.createHorseForm.value.disciplines[key] === true) { return key; };
    });
    fValues.age = parseInt(fValues.age, 10);
    const horse = new Horse().deserialize(fValues);
    horse.customerId = this.customer.id;

    this.apiService.horses.create(horse)
    .then((response) => {
      this.horseCreated.emit(response.data);
      if (response.success) {

        Swal.fire({
          icon: 'success',
          title : 'New horse created successfully'
           })
        .then((dismissed) => {
          this.createHorseForm.reset();
        })
        .catch((err) => { console.log(err); });
      }
    })
    .catch((err) => { console.log(err) });
  }

  CancelCreateHorse() {
    this.cancelled.emit(null);
  }
}
