<div class="page">
<div class="appointment-details">

<ubc-details-table-form
*ngIf="appointmentDetailsForm && !editAddressOpen"
[formGroup]="appointmentDetailsForm"
[tables]="tables"
(editClickedEmitter)="editClicked($event)"
(formSubmittedEmitter)="appointmentDetailsSubmitted()"
(itemEditClickedEmitter)="itemEditClicked($event)"
[showDeleteButton]="true"
(deleteClicked)="appointmentDeleteClicked()"
></ubc-details-table-form>

</div>

<div class="address-update-wrap" *ngIf="appointment && editAddressOpen">
  <div class="flex-row header-row">
    <div>
    <h3>Update address for appointment</h3>
    <p>{{appointment.customer.getFullName()}}
      <br>

      {{appointment.getTimesSummary()}}
    </p>
    </div>

  </div>
<ubc-address-picker
(cancelled)="addressPickerCancelled()"
(addressPicked)="addressPicked($event)"
[customer]="appointment.customer"
#addressPickerComponent></ubc-address-picker>


<ubc-address-creator
*ngIf="addressCreatorOpen"
[customer]="appointment.customer"
(addressCreated)="addressCreated($event)"
(cancelled)="addressCreateCancelled()"
></ubc-address-creator>


<button id="cancel-button" mat-raised-button color="basic" (click)="addressChangeCancelClicked()" >Cancel</button>
</div>


