import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'ubc-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})
export class VerifyCodeComponent implements OnInit, AfterViewInit {

  @ViewChild('code1', {}) codeInput1: ElementRef;
  @ViewChild('code2', {}) codeInput2: ElementRef;
  @ViewChild('code3', {}) codeInput3: ElementRef;
  @ViewChild('code4', {}) codeInput4: ElementRef;
  @ViewChild('code5', {}) codeInput5: ElementRef;
  @ViewChild('code6', {}) codeInput6: ElementRef;

  @Output() codeSubmitted = new EventEmitter();

  code = new Array(6);
  constructor(private renderer: Renderer2) { }

  ngAfterViewInit(): void{
    if(this.codeInput1 != null){
      this.renderer.listen(this.codeInput1.nativeElement, 'paste', (event) => {
        const pastedCode: any[] = event.clipboardData.getData('Text').split('');
        let i = 0;
        const newCode = new Array(6);

        pastedCode.forEach((digit)=>{
          if(!isNaN(parseFloat(digit)) && !isNaN(digit - 0)){
            newCode[i] = digit;
          }

          i++;

        });
        setTimeout(()=>{
          this.code = [...newCode];


        },0)


      });
    }
  }

  ngOnInit(): void {

  }



  keyPressed($event, val, nextElementIndex, prevElement){

    const charCode = ($event.which) ? $event.which : $event.keyCode;

    if(charCode < 47 || charCode > 57){
      // a number was not entered
      if(charCode === 86){
        return true;
      }
      if(charCode != 8){
        return false;
      }

    }

    const keysToIgnoreForFocusChange = [9,13,16,17,18,91,20,46]


    if(charCode === 8){
      console.log(val)
      if(val.length === 0){
        switch (prevElement){
          case 1:{
            setTimeout(()=>{this.codeInput1.nativeElement.focus();},0);
            break;
          }
          case 2:{
            setTimeout(()=>{this.codeInput2.nativeElement.focus();},0);
            break;
          }
          case 3:{
            setTimeout(()=>{this.codeInput3.nativeElement.focus();},0);
            break;
          }
          case 4:{
            setTimeout(()=>{this.codeInput4.nativeElement.focus();},0);
            break;
          }
          case 5:{
            setTimeout(()=>{this.codeInput5.nativeElement.focus();},0);
            break;
          }
          case 0:{
            // setTimeout(()=>{this.submitCode();},0);
            break;
          }
        }
      } else {
        val = '';
        return true;
      }
    }



    if(val && val.length === 1){
      return false;
    }



    if(charCode != 8 && !keysToIgnoreForFocusChange.includes(charCode)){
      // only try to move to next if key wasnt backspace

      switch (nextElementIndex){
        case 1:{
          setTimeout(()=>{this.codeInput2.nativeElement.focus();},0);
          break;
        }
        case 2:{
          setTimeout(()=>{this.codeInput3.nativeElement.focus();},0);
          break;
        }
        case 3:{
          setTimeout(()=>{this.codeInput4.nativeElement.focus();},0);
          break;
        }
        case 4:{
          setTimeout(()=>{this.codeInput5.nativeElement.focus();},0);
          break;
        }
        case 5:{
          setTimeout(()=>{this.codeInput6.nativeElement.focus();},0);
          break;
        }
        case 0:{
          // setTimeout(()=>{this.submitCode();},0);
          break;
        }
      }
    }

    return true;
  }

  codeIsValid(): boolean{
    if(this.code){
    const code = this.code.join('');
    return (code.length === 6);
    }
    return false;
  }

  submitCode(){
    const code = this.code.join('');
    if(code.length === 6){
      console.log('submit code: ', code)
      this.codeSubmitted.emit(code);
      return;
    }
    console.log('code is not complete :: ', code);
  }

  public clearCode(){
    this.code = new Array(6);
  }

}
