export class AppointmentAPIBody {
  customerId: number;
  horseId: number;
  startTime: string;
  endTime: string;
  addressId: number;
  typeId: number;
  saddleTypeId: number;
  notes: string;
  calendarId: string;
  status: string;
  meta: any;
  fitterId: number;
  budget: number;
  newSaddleInterest: boolean;
  isProvisional: boolean;
  sendDetailsForm: boolean;
}
