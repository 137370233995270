import { IDeserializable } from './deserializable.model';
import { AppointmentStatus } from './appointment-status.enum';
import { SaddleType } from './saddle-type.model';
import { Address } from './address.model';
import * as moment from 'moment';
import { Customer } from './customer.model';
import { Horse } from './horse.model';
import { Fitter } from './fitter.model';
import { AppointmentType } from './appointment-type.model';

export class Appointment implements IDeserializable {

constructor(){}

  public id: number;
  public hashedId?: string;
  public customer: Customer;
  public horse: Horse;
  public startTime: moment.Moment;
  public endTime: moment.Moment;
  public address: Address;
  public saddleType: SaddleType;
  public notes: string;
  public calendarId: string;
  public status: string;
  public meta: any;
  public fitter: Fitter;
  public type: AppointmentType;
  public budget: number;
  public newSaddleInterest: boolean;

  public startHour: number;
  public endHour: number;
  public startMinute: number;
  public endMinute: number;

  public getTimesSummary(){
    return this.startTime.format('h:mm') + ' - ' + this.endTime.format('h:mma') + ' ' + this.startTime.format('Do MMMM YYYY');
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    this.fitter = new Fitter().deserialize(this.fitter);
    this.address = new Address().deserialize(this.address);
    this.customer = new Customer().deserialize(this.customer);
    this.horse = new Horse().deserialize(this.horse);
    this.startTime = moment(this.startTime);
    this.endTime = moment(this.endTime);
    this.saddleType = new SaddleType().deserialize(this.saddleType);
    return this;
  }



}
