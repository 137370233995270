import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './views/home-page/home-page.component';
import { UserHomeComponent } from './views/user-home/user-home.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { UsersPageComponent } from './views/users-page/users-page.component';
import { CustomersPageComponent } from './views/customers-page/customers-page.component';
import { CalendarPageComponent } from './views/calendar-page/calendar-page.component';
import { AppointmentPageComponent } from './views/appointment-page/appointment-page.component';
import { CustomerDetailsPageComponent } from './views/customer-details-page/customer-details-page.component';
import { HorseDetailsPageComponent } from './views/horse-details-page/horse-details-page.component';
import { NewBookingInfoComponent } from './views/new-booking-info/new-booking-info.component';
import { MyAccountComponent } from './views/my-account/my-account.component';
import { CustomerLoginComponent } from './views/customer-login/customer-login.component';
import { CustomerAuthService } from './auth/customer-auth.service';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToSendEmail = () => redirectLoggedInTo(['my-account']);

const routes: Routes = [
  { path : '', component: HomePageComponent },
  { path : 'home', component: UserHomeComponent, canActivate: [AuthGuardService], data: { title: 'My Account'} },
  { path : 'users', component: UsersPageComponent, canActivate: [AuthGuardService], data: { title: 'Users'} },
  { path : 'customers', component: CustomersPageComponent, canActivate: [AuthGuardService], data: { title: 'Customers'} },
  { path : 'calendar', component: CalendarPageComponent, canActivate: [AuthGuardService], data: { title: 'Calendar'} },
  { path : 'appointment/:id', component: AppointmentPageComponent, canActivate: [AuthGuardService], data: { title: 'Appointment'} },
  { path : 'customer/:id', component: CustomerDetailsPageComponent, canActivate: [AuthGuardService], data: { title: 'Customer'} },
  { path : 'horse/:id', component: HorseDetailsPageComponent, canActivate: [AuthGuardService], data: { title: 'Horse'} },
  { path : 'booking/new/info/:id', component: NewBookingInfoComponent, canActivate: [AngularFireAuthGuard, CustomerAuthService], data: { title: 'New Booking Information', authGuardPipe: redirectUnauthorizedToLogin} },
  { path : 'my-account', component: MyAccountComponent, canActivate: [AngularFireAuthGuard, CustomerAuthService], data: { title: 'My Account', authGuardPipe: redirectUnauthorizedToLogin} },
  { path : 'login', component: CustomerLoginComponent, canActivate: [AngularFireAuthGuard], data: { title: 'Customer Login', authGuardPipe: redirectLoggedInToSendEmail} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
