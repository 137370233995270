import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { Customer } from 'src/app/models/customer.model';
import { MatSelectionList, MatSelectionListChange, MatListOption } from '@angular/material/list';

@Component({
  selector: 'ubc-customer-picker',
  templateUrl: './customer-picker.component.html',
  styleUrls: ['./customer-picker.component.scss']
})
export class CustomerPickerComponent implements OnInit, AfterViewInit {

  @Output() customerPicked = new EventEmitter();
  @Output() newCustomerClicked = new EventEmitter();
  private customerSearchInput: ElementRef;
  searchResults: Customer[];
  searchInProgress = false;

  constructor(private apiService: APIServiceService) { }

  @ViewChild(MatSelectionList, {}) customerSelect: MatSelectionList;

  @ViewChild('customerSearchInput', {}) set element(customerSearchInput: ElementRef) {
    this.customerSearchInput = customerSearchInput;
    if (!this.customerSearchInput) {
      console.log('Element is null');
      return;
    }
    fromEvent(this.customerSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 1)
      , debounceTime(500)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.searchInProgress = true;
      this.apiService.customer.search(text)
      .subscribe((res) =>{
        this.searchInProgress = false;
        if (res.success) {
          this.searchResults = res.data.results;
        }
      });
    });
 }


 selectCustomer(customer: Customer) {
  this.customerPicked.emit(customer);
 }

 newCustomerButtonClicked() {
  this.newCustomerClicked.emit(null);
 }

 ngAfterViewInit() {

  setTimeout(()=>{ // this will make the execution after the above boolean has changed
    this.customerSearchInput.nativeElement.focus();
  },0);

  this.customerSelect.selectionChange.subscribe((s: MatSelectionListChange) => {

    this.customerSelect.deselectAll();
    s.option.selected = true;
    const option: MatListOption = s.option;
    this.customerPicked.emit(option.value);

  });
 }

  ngOnInit() {

  }



}
