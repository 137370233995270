import { Component, OnInit, Input, EventEmitter, Output, ViewChild, NgZone } from '@angular/core';
import { Appointment } from 'src/app/models/appointment.model';
import { Customer } from 'src/app/models/customer.model';
import { CustomerPickerComponent } from '../customer-picker/customer-picker.component';
import { Horse } from 'src/app/models/horse.model';
import { HorsePickerComponent } from '../horse-picker/horse-picker.component';
import { Address } from 'src/app/models/address.model';
import { AppointmentType } from 'src/app/models/appointment-type.model';
import { AppointmentManagerService } from 'src/app/services/appointment-manager.service';
import { AppointmentStatus } from 'src/app/models/appointment-status.enum';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { MatStepper, MatStep } from '@angular/material/stepper';
import { AddressPickerComponent } from '../address-picker/address-picker.component';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { EntityTypesService } from 'src/app/services/entity-types.service';
import { Fitter } from 'src/app/models/fitter.model';

@Component({
  selector: 'ubc-appointment-creator',
  templateUrl: './appointment-creator.component.html',
  styleUrls: ['./appointment-creator.component.scss']
})
export class AppointmentCreatorComponent implements OnInit {

  static AUTO_STEP_DELAY = 200;
  @Input() appointment: Appointment;

  @Output() wasCancelled = new EventEmitter();
  @Output() wasCreated = new EventEmitter();

  stepper: MatStepper;

  // icons
  faWindowClose = faWindowClose;

  // Stage switches
  createCustomer = false;
  newHorse: Horse;
  newAddress: Address;


  appointmentIsProvisional = false;
  sendDetailsForm = false;

  customerPickerComponent: CustomerPickerComponent;
  horsePickerComponent: HorsePickerComponent;
  addressPickerComponent: AddressPickerComponent;
  addressCreatorOpen = false;

  fitters: Fitter[];


  constructor(
    private appointmentManager: AppointmentManagerService,
    private apiService: APIServiceService,
    private ngZone: NgZone,
    private entityTypesService: EntityTypesService) { }

  ngOnInit() {
    this.entityTypesService.getFitters()
    .then((fitters) => {
      this.fitters = fitters;
    })
    .catch((err) => { console.log(err) });
  }

  @ViewChild('stepper', {}) set st(stepper: MatStepper) {
    this.stepper = stepper;
  }

  stepperStepChanged(step: any) {
    //console.log('STEP CHANGED TO ', step.selectedIndex);
    if (step.selectedIndex === 1) {
      if (this.appointmentIsProvisional) {
        this.stepper.selectedIndex = 0;
        return;
      }
    }
    if (step.selectedIndex === 2 && !this.appointment.address) {
      this.addressPickerComponent.getAddresesForCustomer();
    }
  }

  // DEFINE CUSTOMER


  @ViewChild('customerPickerComponent', {}) set picker(picker: CustomerPickerComponent) {
    this.customerPickerComponent = picker;
  }

  customerStageCompleteClicked(){
    if (!this.appointment.customer || !this.appointment.customer.id) {
      return;
    }
    if (this.appointmentIsProvisional) {
      this.createAppointment();
      return;
    }
    this.stepper.next();
  }

  customerCreateCancelled() {
    this.createCustomer = false;
  }
  customerCreated(customer: Customer) {
    this.createCustomer = false;
    this.ngZone.run(() => {

      this.appointment.customer = customer;
      if (this.appointmentIsProvisional) {
        return;
      }
      setTimeout(() => {
        this.stepper.next();
      }, AppointmentCreatorComponent.AUTO_STEP_DELAY);
    });

  }
  customerPicked(customer: Customer) {
    this.appointment.customer = customer;
    this.customerPickerComponent.searchResults = [];

    if(!this.horsePickerComponent.customer || (this.appointment.customer.id !== this.horsePickerComponent.customer.id)) {
      console.log('Gettng horses for customer ', this.appointment.customer.getFullName());
      this.horsePickerComponent.getHorsesForCustomer(this.appointment.customer);
      this.addressPickerComponent.getAddresesForCustomer(this.appointment.customer);
      this.appointment.horse = null;
      this.appointment.address = null;

    }

    if (this.appointmentIsProvisional) {
      return;
    }

    this.ngZone.run(() => {
      setTimeout(() => {
        this.stepper.next();
      }, AppointmentCreatorComponent.AUTO_STEP_DELAY);
    });
  }

  // DEFINE HORSE


  @ViewChild('horsePickerComponent', {}) set horsePicker(horsePicker: HorsePickerComponent) {
    this.horsePickerComponent = horsePicker;
  }

  horsePicked(horse: Horse) {
    if (horse.id) {
      this.appointment.horse = horse;
      console.log('Horse picked');
      this.ngZone.run(() => {
        setTimeout(() => {
          this.stepper.next();
        }, AppointmentCreatorComponent.AUTO_STEP_DELAY);
      });
      return;
    }
    // If the horse does not have an ID its a new horse, and need to be configured
    this.addHorseClicked();
  }
  addHorseClicked() {
    this.newHorse = new Horse();
    this.appointment.horse = this.newHorse;

  }

  newHorseCreated(horse: Horse) {
    this.appointment.horse = horse;
    this.horsePickerComponent.getHorsesForCustomer(this.appointment.customer);
    console.log(this.appointment);
  }

  newHorseCancelled() {
    this.appointment.horse = null;
  }

  // DEFINE ADDRESS
  @ViewChild('addressPickerComponent', {}) set addPicker(addressPicker: AddressPickerComponent) {
    this.addressPickerComponent = addressPicker;
  }
  createAddressClicked() {
    this.addressCreatorOpen = true;
    this.newAddress = new Address();
    this.appointment.address = this.newAddress;
  }
  addressPickerCancelled() {
    this.addressCreatorOpen = false;
    this.appointment.address = null;
  }
  addressPicked(address: Address) {
    if (address.id) {
      this.addressCreatorOpen = false;
      this.appointment.address = address;
      this.ngZone.run(() => {
        setTimeout(() => {
          this.stepper.next();
        }, AppointmentCreatorComponent.AUTO_STEP_DELAY);
      });
      return;
    }
    // If the address does not have an ID its a new address, and need to be configured
    this.createAddressClicked();
  }

  addressCreated(address: Address) {
    this.addressCreatorOpen = false;
    this.appointment.address = address;
    this.newAddress = null;
    this.ngZone.run(() => {
    setTimeout(() => {
      this.addressPickerComponent.getAddresesForCustomer(this.appointment.customer);
    }, 300);
    })
  }

  addressCreateCancelled() {
    this.addressCreatorOpen = false;
    this.appointment.address = null;
    setTimeout(() => {
      this.addressPickerComponent.getAddresesForCustomer(this.appointment.customer);
    }, 300);

  }


  // DEFINE APPOINTMENT DETAILS
  detailsUpdated(appointment: Appointment) {
    console.log('Details updated: ', this.appointment);
    this.createAppointment();
  }

  createAppointment() {
    this.appointment.status = AppointmentStatus[AppointmentStatus.PENDING];
    const body = this.appointmentManager.createAPIBody(this.appointment);
    // console.log('Create appointment via API', this.appointment);
    body.isProvisional = this.appointmentIsProvisional; // Set is provisional flag
    body.sendDetailsForm = this.sendDetailsForm;

    this.apiService.appointments.create(body)
    .then((response) => {
      console.log('Created appointment: ', response);
      this.wasCreated.emit(null);
    })
    .catch((err) => { console.log(err) });
  }

  cancelClicked(){
    this.cancel();
  }

  cancel() {
    this.appointment = null;
    this.wasCancelled.emit(null);
  }


  getProgressStage(): number {
    if (this.appointment.address && this.appointment.address.id) {
      return 3;
    }

    if (this.appointment.horse && this.appointment.horse.id) {
      return 2;
    }

    if (this.appointment.customer && this.appointment.customer.id) {
      return 1;
    }







  }

}
