import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedInNavComponent } from 'src/app/components/ui/logged-in-nav/logged-in-nav.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [LoggedInNavComponent],
  imports: [
    AppRoutingModule,
    CommonModule,
    MatMenuModule,
    FontAwesomeModule
  ],
  exports: [
    LoggedInNavComponent,
    FontAwesomeModule
  ]
})
export class SharedUIModuleModule { }
