import { Component, OnInit, Input } from '@angular/core';
import { Appointment } from 'src/app/models/appointment.model';

@Component({
  selector: 'ubc-appointment-summary',
  templateUrl: './appointment-summary.component.html',
  styleUrls: ['./appointment-summary.component.scss']
})
export class AppointmentSummaryComponent implements OnInit {

  @Input() appointment: Appointment;
  constructor() { }

  ngOnInit(): void {
  }

}
