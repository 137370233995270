import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/auth/user-auth.service';
import { APIServiceService } from 'src/app/api/apiservice.service';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit {

  constructor(
    public userAuthService: UserAuthService,
    private apiService: APIServiceService
    ) { }

  ngOnInit() {
  }

  signOut() {
    this.userAuthService.signOut();
  }





}
