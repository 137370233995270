<form [formGroup]="formGroup" (ngSubmit)="formSubmittedClicked()">

  <div class="header" *ngIf="!hideHeader">

    <button mat-raised-button color="primary" type="submit" [disabled]="!formGroup.dirty || formGroup.invalid">Save Changes</button>
    <a mat-raised-button color="warn" (click)="deleteBookingClicked()" *ngIf="showDeleteButton">Delete Booking</a>
  </div>

  <table class="details-table" *ngFor="let table of tables">
<thead>
  <tr>
    <th colspan="2">{{(table.firstFieldIsTitle) ? table.rows[0].value : table.title}}
    <span *ngIf="table.editable"  class="edit"><span (click)="editClicked(table.editClickAction)"><fa-icon [icon]="faEdit"></fa-icon>Edit</span></span>
  </th>
  </tr>
</thead>
<tbody>
  <tr *ngFor="let row of table.rows" [hidden]="row.field === 'hidden'" >
    <td *ngIf="!row.hide" class="key">{{row.key}}</td>
    <td *ngIf="!row.hide" class="value">
      <span *ngIf="!row.field" [innerHTML]="row.value"></span>

      <input *ngIf="row.field === 'hidden'" type="hidden" [formControl]="row.formControl" value="{{row.value}}" />

      <mat-form-field *ngIf="row.field === 'text'">
        <input matInput [formControl]="row.formControl" value="{{row.value}}">
      </mat-form-field>



      <mat-form-field *ngIf="row.field === 'textarea'">
        <textarea matInput [formControl]="row.formControl"></textarea>
      </mat-form-field>

      <mat-form-field *ngIf="row.field === 'select'">
          <mat-select  [formControl]="row.formControl">
          <mat-option *ngFor="let option of row.options" [(value)]="option.value">
            {{option.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="row.field === 'checkboxes'" formGroupName="disciplines">
        <mat-checkbox formControlName="ALLROUNDER">All Rounder</mat-checkbox>&nbsp;
        <mat-checkbox formControlName="DRESSAGE">Dressage</mat-checkbox>&nbsp;
        <mat-checkbox formControlName="JUMPING">Jumping</mat-checkbox>&nbsp;
        <mat-checkbox formControlName="EVENTING">Eventing</mat-checkbox>&nbsp;
        <mat-checkbox formControlName="HACKING">Hacking</mat-checkbox>&nbsp;
        <mat-checkbox formControlName="ENDURANCE">Endurance</mat-checkbox>&nbsp;
        <mat-checkbox formControlName="SHOWING">Showing</mat-checkbox>&nbsp;
      </div>

      <mat-checkbox *ngIf="row.field === 'checkbox'" [formControl]="row.formControl">Is Affiliated?</mat-checkbox>

      <span class="item-edit-button" *ngIf="row.editAction && row.editAction != ''"><span (click)="itemEditClicked(row.editAction)"><fa-icon [icon]="faEdit"></fa-icon>Edit</span></span>

    </td>
  </tr>
</tbody>
</table>
<div class="footer">
<button mat-raised-button color="primary" type="submit" [disabled]="!formGroup.dirty || formGroup.invalid">Save Changes</button>
<a mat-raised-button color="warn" (click)="deleteBookingClicked()" *ngIf="showDeleteButton">Delete Booking</a>
</div>
</form>
