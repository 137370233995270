

<div class="content-wrap">




<form id="payment-form" >
  <div  id="card-element"><!--Stripe.js injects the Card Element--></div>
  <button id="submit" [disabled]="paymentSuccess">
    <div class="spinner hidden" id="spinner"></div>
    <span id="button-text" >Secure Payment</span>
  </button>
  <p id="card-error" role="alert"></p>
  <p class="result-message" *ngIf="paymentSuccess">
    Payment completed. Thank you.<br>

  </p>
</form>
</div>
