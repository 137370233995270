import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Horse } from 'src/app/models/horse.model';
import Swal from 'sweetalert2';
import { HorseDiscipline } from 'src/app/models/horse-disciplines.enum';
import { Customer } from 'src/app/models/customer.model';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import { Appointment } from 'src/app/models/appointment.model';

@Component({
  selector: 'ubc-horse-details-page',
  templateUrl: './horse-details-page.component.html',
  styleUrls: ['./horse-details-page.component.scss']
})
export class HorseDetailsPageComponent implements OnInit, AfterViewInit {
  horse: Horse;
  horsesDetailsForm: FormGroup;
  tables = [];
  showHorseSwitcher = false;
  showAppointmentData = false;
  customer: Customer;
  appointment: Appointment;
  customersHorsesDataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [ 'name', 'breed', 'colour', 'height', 'age', 'sex', 'notes', 'view'];

  constructor(private route: ActivatedRoute, private apiService: APIServiceService, private location: Location) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    if (!this.route.snapshot.params.id) {
      return;
    }
    this.initView();
  }

  initView(){
    this.getHorse();
    if (this.route.snapshot.queryParams.appointment && this.route.snapshot.queryParams.customer) {
      this.getCustomer();
    }
  }

  getHorse() {
    this.apiService.horses.getByID(this.route.snapshot.params.id)
    .then((response) => {
      if (response.success){
        this.horse = response.data;
        // console.log('Got horse: ', response);
        this.prepareHorseFormForDisplay();
      }



    })
    .catch((err) => { console.log(err) });
  }

  getCustomer(){

    this.apiService.customer.getByID(this.route.snapshot.queryParams.customer, true)
    .then((response) => {
      if (response.success) {
        this.showAppointmentData = true;
        this.customer = new Customer().deserialize(response.data);
        this.customer.horses.forEach((horse, index, object) => {
          if (this.horse && horse.id === this.horse.id){
            object.splice(index, 1);
          }
        });
        this.customersHorsesDataSource = new MatTableDataSource(this.customer.horses);
        if (this.customer.horses.length > 0) {
          this.showHorseSwitcher = true;
        }

        // Get appointment details for the appointment summary header
        this.apiService.appointments.getByID(this.route.snapshot.queryParams.appointment)
        .then((apptResponse) => {
        if (apptResponse.success) {
          this.appointment = new Appointment().deserialize(apptResponse.data);
        }
        })
        .catch((err) => { console.log(err)});

      }
    })
    .catch((err) => { console.log(err)});
  }

  prepareHorseFormForDisplay() {

    if (!this.horse) {
      return;
    }

    const disciplineControls = {};
    const disciplinesCopy: any = this.horse.disciplines.map((d) => d);
    let i = 0;
    for (const discipline in HorseDiscipline) {
      if (!discipline) { continue; }
      const isSelected = (disciplinesCopy.includes(discipline) );
      disciplineControls[discipline] = new FormControl(isSelected);
      i++;
    }

    this.horsesDetailsForm = new FormGroup({
      name : new FormControl(this.horse.name),
      breed : new FormControl(this.horse.breed),
      colour : new FormControl(this.horse.colour),
      height : new FormControl(this.horse.height),
      sex : new FormControl(this.horse.sex),
      age : new FormControl(this.horse.age),
      notes : new FormControl(this.horse.notes),
      isAffiliated : new FormControl(this.horse.isAffiliated),
      disciplines : new FormGroup(disciplineControls)

    });


    const horseDetails = {
      title: 'Horse Details',
      editable: false,
      editClickAction: 'horseEditClicked',
      rows: [
        {
          key : 'Name',
          value: this.horse.name,
          field: 'text',
          formControl: this.horsesDetailsForm.controls.name
        },{
          key : 'Breed',
          value: this.horse.breed,
          field:  'text',
          formControl: this.horsesDetailsForm.controls.breed
        },{
          key : 'Colour',
          value: this.horse.colour,
          field: 'text',
          formControl: this.horsesDetailsForm.controls.colour
        },
        {
          key : 'Sex',
          value: this.horse.sex,
          field:  'text',
          formControl: this.horsesDetailsForm.controls.sex
        },
        {
          key : 'Age',
          value: this.horse.age,
          field:  'text',
          formControl: this.horsesDetailsForm.controls.age
        },
        {
          key : 'Height',
          value: this.horse.height,
          field:  'text',
          formControl: this.horsesDetailsForm.controls.height
        },
        {
          key : 'Notes',
          value: this.horse.notes,
          field:  'textarea',
          formControl: this.horsesDetailsForm.controls.notes
        },
        {
          key: 'Disciplines',
          value: this.horse.disciplines,
          field: 'checkboxes'
        },
        {
          key: 'Is Affiliated',
          value: this.horse.isAffiliated,
          field: 'checkbox',
          formControl: this.horsesDetailsForm.controls.isAffiliated
        }
      ]
    };

    this.tables.push(horseDetails);
  }

  editClicked(clckAction){

  }

  horseDetailsSubmitted(){
    const detailsToUpdate: any = {}
    Object.keys(this.horsesDetailsForm.controls).forEach(key => {
      if (this.horsesDetailsForm.controls[key].touched) {
        detailsToUpdate[key] = this.horsesDetailsForm.controls[key].value;
      }
    });

    if (Object.keys(detailsToUpdate).length === 0) {
      Swal.fire({ icon: 'error', title : 'There are no changes to save', timer: 1000 });
      return;
    }

    if (Object.keys(detailsToUpdate).includes('disciplines')){
      const disciplines = [];
      Object.keys(detailsToUpdate.disciplines).forEach(key => {
        if (detailsToUpdate.disciplines[key]) {
          disciplines.push(key);
        }
      });
      detailsToUpdate.disciplines = JSON.stringify(disciplines);
    }

    this.apiService.horses.patch(this.horse.id, detailsToUpdate)
    .then((response) => {
      if (response.success) {
        Swal.fire({ icon: 'success', title : 'Horse details saved' }).then((confirmed) => {
          if(confirmed && this.route.snapshot.queryParams.appointment){
            this.location.back();
          }
        })
        .catch((err) => { console.log(err) });

      } else {
        Swal.fire({ icon: 'error', title : 'Error updating horse' });
      }
    })
    .catch((err) => { console.log(err)});
  }


  horseFromTableClicked(horseId) {
    this.apiService.appointments.patch(this.route.snapshot.queryParams.appointment, { horseId})
    .then((response) => {
      if (response.success) {
        Swal.fire({ icon: 'success', title : 'Horse updated for appointment' }).then((confirmed) => {
          if(confirmed && this.route.snapshot.queryParams.appointment){
            this.location.back();
          }
        })
        .catch((err) => { console.log(err) });

      }
    })
    .catch((err) => { console.log(err) });
  }

  newHorseCreated(newHorse){
    this.horse = newHorse;

  }

  horseCreateCancelled($event){

  }


}
