<div class="verify-code-wrap">
<div class="verify-code-field-wrap">
  <div class="code-item">
    <input type="text" #code1 [(ngModel)]="code[0]" (focus)="code[0] = ''" (keydown)="keyPressed($event, code[0], 1, 0)"  />
  </div>
  <div class="code-item">
    <input type="text" #code2 [(ngModel)]="code[1]" (focus)="code[1] = ''" (keydown)="keyPressed($event, code[1], 2, 1)" />
  </div>
  <div class="code-item">
    <input type="text" #code3 [(ngModel)]="code[2]" (focus)="code[2] = ''" (keydown)="keyPressed($event, code[2], 3, 2)" />
  </div>
  <div class="code-item divider">-</div>
  <div class="code-item">
    <input type="text" #code4 [(ngModel)]="code[3]" (focus)="code[3] = ''" (keydown)="keyPressed($event, code[3], 4, 3)" />
  </div>
  <div class="code-item">
    <input type="text" #code5 [(ngModel)]="code[4]" (focus)="code[4] = ''" (keydown)="keyPressed($event, code[4], 5, 4)" />
  </div>
  <div class="code-item">
    <input type="text" #code6 [(ngModel)]="code[5]" (focus)="code[5] = ''" (keydown)="keyPressed($event, code[5], 0, 5)" />
  </div>
</div>
<div class="btn" (click)="submitCode()" [ngClass]="{'disabled' : !codeIsValid()}">Continue</div>
</div>

