import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { APIResponse } from 'src/app/models/api-response.model';
import { fromEvent, Observable, timer } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged, debounce, first, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AddressesServiceService, GoogleAddressResult } from 'src/app/services/addresses-service.service';

@Component({
  selector: 'ubc-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {

  customer: Customer;
  @Output() customerCreated = new EventEmitter();
  @Output() customerCreateCancelled = new EventEmitter();
  step = 0;
  createCustomerForm: FormGroup;
  addressSearchField: ElementRef;
  addressSearchResults: any;

  constructor(private apiService: APIServiceService, private addressService: AddressesServiceService) { }

  @ViewChild('addressSearchField', {}) set element(addressSearchField: ElementRef) {
    this.addressSearchField = addressSearchField;
    if (!this.addressSearchField) {
      return;
    }
    fromEvent(this.addressSearchField.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 1)
      , debounceTime(500)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.apiService.addresses.geoLookup(text)
      .subscribe((res) =>{
        if (res.success && res.data.status === 'OK') {
          this.addressSearchResults = res.data.results;
          console.log(this.addressSearchResults);
        } else {
          this.addressSearchResults = [];
        }
      });
    });
 }

  ngOnInit() {
    this.createCustomerForm = new FormGroup({
      personalDetails : new FormGroup({
        firstName : new FormControl(null, [Validators.required]),
        lastName : new FormControl(null, [Validators.required]),
        email : new FormControl(null, [Validators.required], [this.checkEmailAvailability.bind(this)]),
        tel : new FormControl(null, []),
      }),
      billingDetails: new FormGroup({
        billingAddress1 : new FormControl(null),
        billingAddress2 : new FormControl(null),
        billingTown : new FormControl(null),
        billingCity : new FormControl(null),
        billingPostcode : new FormControl(null)
      }),
      notes : new FormControl(null)
    });
  }



  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  customerCreationComplete() {
    this.customerCreated.emit(this.customer);
  }

  customerCreationCancelled() {
    this.customerCreateCancelled.emit(null);
  }

  createCustomerFormSubmitted() {
      const customer: Customer = new Customer();
      console.log(this.createCustomerForm.value.personalDetails);
      for(const key of Object.keys(this.createCustomerForm.value.personalDetails)) {
        customer[key] = this.createCustomerForm.value.personalDetails[key];
      }
      for(const key of Object.keys(this.createCustomerForm.value.billingDetails)) {
        customer[key] = this.createCustomerForm.value.billingDetails[key];
      }
      customer.notes = this.createCustomerForm.value.notes;
      this.apiService.customer.create(customer)
      .then((response: APIResponse) => {
        if (response.success) {
          const newCustomer = new Customer().deserialize(response.data);
          this.customerCreated.emit(newCustomer);
        }
      })
      .catch((err) => {
        console.log(err);
      });



  }

  getDisplayAddressFromResult(address?: any) {
    return address ? address.formatted_address : undefined;
  }

  addressSearchResultSelected(option: MatAutocompleteSelectedEvent) {
    console.log(option.option.value);
    this.populateAddressWithSearchresult(option.option.value)
  }

  populateAddressWithSearchresult(addressResult: GoogleAddressResult) {
    const parsedAddress = this.addressService.parseGoogleAddressresult(addressResult);
    this.createCustomerForm
    .get('billingDetails')
    .patchValue(
      { billingAddress1 : parsedAddress.line1,
        billingAddress2 : parsedAddress.line2,
        billingTown: parsedAddress.town,
        billingCity: parsedAddress.city,
        billingPostcode: parsedAddress.postcode
      }
    );
  }

  checkEmailAvailability(control: FormControl): Observable<any> {
    console.log('Checking email');
    return control.valueChanges.pipe(
      debounce(() => timer(500)),
      first(),
      switchMap(() => {
        return this.apiService.customer.isEmailtaken(this.createCustomerForm.get('personalDetails.email').value)
        .pipe(
          map((
            (response) => {
              if(response.data.isAvailable) {
                console.log('EMAIL IS AVAILABLE');
                return null;
              }
              console.log('EMAL IS TAKEN');
              return { emailIsInUse: true };
            }
            )
        ));
      })
    );
  }

}
