import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider
} from 'angularx-social-login';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomePageComponent } from './views/home-page/home-page.component';
import { UserHomeComponent } from './views/user-home/user-home.component';
import { UsersPageComponent } from './views/users-page/users-page.component';
import { SharedUIModuleModule } from './shared/shared-uimodule/shared-uimodule.module';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomersPageComponent } from './views/customers-page/customers-page.component';
import { AppointmentCreatorComponent } from './components/appointment-creator/appointment-creator.component';
import { CalendarPageComponent } from './views/calendar-page/calendar-page.component';
import { CreateCustomerComponent } from './components/create-customer/create-customer.component';
import { CustomerPickerComponent } from './components/customer-picker/customer-picker.component';
import { HorsePickerComponent } from './components/horse-picker/horse-picker.component';
import { CreateHorseComponent } from './components/create-horse/create-horse.component';
import { AddressPickerComponent } from './components/address-picker/address-picker.component';
import { AddressCreatorComponent } from './components/address-creator/address-creator.component';
import { AppointmentDetailsComponent } from './components/appointment-details/appointment-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';

// import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
// import { MonthDayComponent } from './components/CalendarElements/month-day/month-day.component';
// import { WeekDayComponent } from './components/CalendarElements/week-day/week-day.component';
// import { DayComponent } from './components/CalendarElements/day/day.component';
// import { DragDropModule } from '@angular/cdk/drag-drop';
// import { WeekDayHeaderComponent } from './components/CalendarElements/week-day-header/week-day-header.component';
// import { SideBarMonthDayComponent } from './components/CalendarElements/side-bar-month-day/side-bar-month-day.component';

import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppointmentPageComponent } from './views/appointment-page/appointment-page.component';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CustomerDetailsPageComponent } from './views/customer-details-page/customer-details-page.component';
import { DetailsTableFormComponent } from './components/details-table-form/details-table-form.component';
import { HorseDetailsPageComponent } from './views/horse-details-page/horse-details-page.component';
import { HorsesTableComponent } from './components/horses-table/horses-table.component';
import { AppointmentSummaryComponent } from './components/appointment-summary/appointment-summary.component';
import { NewBookingInfoComponent } from './views/new-booking-info/new-booking-info.component';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { PublicHeaderComponent } from './components/public-header/public-header.component';
import { VerifyCodeComponent } from './components/verify-code/verify-code.component';
import { FormSectionEnabledPipe } from './pipes/form-sectoin.pipe';
import { CardPaymentComponent } from './components/card-payment/card-payment.component';
import { ProvisionalBookingComponent } from './components/provisional-booking/provisional-booking.component';

import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { MyAccountComponent } from './views/my-account/my-account.component';
import { CustomerLoginComponent } from './views/customer-login/customer-login.component';


@Injectable()
export class MyHammerConfig extends HammerGestureConfig  {
  overrides = {

  } as any;
}

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('269853954757-6he76vnf98igaarhndaeqfiin692n45r.apps.googleusercontent.com')
  }
]);

export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
      [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider('269853954757-6he76vnf98igaarhndaeqfiin692n45r.apps.googleusercontent.com')
        }
      ]
  );
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    UserHomeComponent,
    UsersPageComponent,
    CustomersPageComponent,
    AppointmentCreatorComponent,
    CalendarPageComponent,
    CreateCustomerComponent,
    CustomerPickerComponent,
    HorsePickerComponent,
    CreateHorseComponent,
    AddressPickerComponent,
    AddressCreatorComponent,
    AppointmentDetailsComponent,
    // CalendarViewComponent,
    // MonthDayComponent,
    // WeekDayComponent,
    // WeekDayHeaderComponent,
    // DayComponent,
    // WeekDayHeaderComponent,
    // SideBarMonthDayComponent,
    AppointmentPageComponent,
    CustomerDetailsPageComponent,
    DetailsTableFormComponent,
    HorseDetailsPageComponent,
    HorsesTableComponent,
    AppointmentSummaryComponent,
    NewBookingInfoComponent,
    PublicHeaderComponent,
    VerifyCodeComponent,
    FormSectionEnabledPipe,
    CardPaymentComponent,
    ProvisionalBookingComponent,
    MyAccountComponent,
    CustomerLoginComponent




  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SocialLoginModule,
    HttpClientModule,
    SharedUIModuleModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FormsModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatStepperModule,
    MatFormFieldModule,
    MatCardModule,
    MatGridListModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatTabsModule,
    MatRadioModule,
    // DragDropModule,
    FullCalendarModule,
    FontAwesomeModule,
    SweetAlert2Module.forRoot(),
    AngularFireModule.initializeApp(environment.firebase)

  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    CookieService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }

  ],
  bootstrap: [AppComponent],
  exports: [
    MatMenuModule,
    // MonthDayComponent,
    // WeekDayComponent,
    // DayComponent,
    // WeekDayHeaderComponent,
    FontAwesomeModule
  ],
  // entryComponents: [MonthDayComponent, WeekDayComponent, DayComponent, WeekDayHeaderComponent, SideBarMonthDayComponent]
})
export class AppModule { }
