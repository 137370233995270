import { Injectable } from '@angular/core';
import { Appointment } from '../models/appointment.model';
import { AppointmentAPIBody } from '../models/appointment-api-body.model';

@Injectable({
  providedIn: 'root'
})
export class AppointmentManagerService {

  constructor() { }

  createAPIBody(appointment: Appointment): AppointmentAPIBody {
    const body = new AppointmentAPIBody();
    body.customerId = appointment.customer.id;
    body.horseId = (appointment.horse) ? appointment.horse.id : 0;
    body.startTime = appointment.startTime.format();
    body.endTime = appointment.endTime.format();
    body.addressId = (appointment.address) ? appointment.address.id : 0;
    body.typeId = (appointment.type) ? appointment.type.id : 13; // Set to provisonal if nos type has been set
    body.saddleTypeId = (appointment.saddleType) ? appointment.saddleType.id : 0;
    body.notes = appointment.notes;
    body.calendarId = appointment.calendarId;
    body.status = appointment.status.toString();
    body.meta = JSON.stringify(appointment.meta);
    body.fitterId = appointment.fitter.id;
    body.budget = appointment.budget;
    body.newSaddleInterest = appointment.newSaddleInterest;
    return body;
  }

}
