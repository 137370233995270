<form [formGroup]="createCustomerForm" (ngSubmit)="createCustomerFormSubmitted()">

  <mat-accordion>
    <!--Personal detail panel-->
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Personal data
        </mat-panel-title>
        <mat-panel-description>
          Name & contact details
          <mat-icon>account_circle</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div formGroupName="personalDetails">
      <mat-form-field>
        <input matInput type="text" formControlName="firstName" placeholder="First name"/>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" formControlName="lastName" placeholder="Last name"/>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="email" formControlName="email" placeholder="Email"/>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="tel" formControlName="tel" placeholder="Telephone"/>
      </mat-form-field>
      </div>
      <mat-action-row>
          <button mat-button type="button"  color="primary" (click)="nextStep()" [disabled]="createCustomerForm.get('personalDetails').invalid">Next</button>
      </mat-action-row>
    </mat-expansion-panel>
    <!--Personal detail panel end-->

     <!--Billing address panel-->
     <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)"  hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Billing Address
          </mat-panel-title>
          <mat-panel-description>
            Billing details
            <mat-icon>house</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div formGroupName="billingDetails">

            <mat-form-field>
                <input matInput [matAutocomplete]="auto" type="text"   placeholder="Address Search..." autocomplete="new-password" #addressSearchField/>
              </mat-form-field>

              <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplayAddressFromResult" (optionSelected)="addressSearchResultSelected($event)">
                  <mat-option *ngFor="let address of addressSearchResults" [value]="address">{{address.formatted_address}}</mat-option>
                </mat-autocomplete>

        <mat-form-field>
            <input matInput type="text"  formControlName="billingAddress1" placeholder="Billing Address 1" autocomplete="new-password"/>
          </mat-form-field>

          <mat-form-field>
            <input matInput type="text" formControlName="billingAddress2" placeholder="Billing Address 2" autocomplete="new-password"/>
          </mat-form-field>

          <mat-form-field>
            <input matInput type="text" formControlName="billingTown" placeholder="Billing Town" autocomplete="new-password"/>
          </mat-form-field>

          <mat-form-field>
            <input matInput type="text" formControlName="billingCity" placeholder="City" autocomplete="new-password"/>
          </mat-form-field>

          <mat-form-field>
            <input matInput type="text" formControlName="billingPostcode" placeholder="Postcode" autocomplete="new-password"/>
          </mat-form-field>
          </div>
        <mat-action-row>
            <button mat-button type="button"  color="warn" (click)="prevStep()">Back</button>
            <button mat-button type="button"  color="primary" (click)="nextStep()" [disabled]="createCustomerForm.get('billingDetails').invalid">Next</button>
        </mat-action-row>
      </mat-expansion-panel>
      <!--Billing address panel end-->

      <!--Notes panel-->
     <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Notes
          </mat-panel-title>
          <mat-panel-description>
            More information about the customer
            <mat-icon>house</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-form-field>
            <textarea matInput formControlName="notes" placeholder="Customer notes"></textarea>
          </mat-form-field>

        <mat-action-row>
            <button mat-button type="button" color="warn" (click)="prevStep()">Back</button>
            <button mat-raised-button type="submit" color="primary" [disabled]="createCustomerForm.invalid">Create Customer</button>
        </mat-action-row>
      </mat-expansion-panel>
      <!--Notes panel end-->


  </mat-accordion>

</form>

<button mat-button type="button" color="warn" (click)="customerCreationCancelled()"><mat-icon>cancel</mat-icon> Cancel create customer</button>
