<div class="public-page-wrap">
  <div class="container" >
    <ubc-public-header></ubc-public-header>


    <h2>My Account</h2>
    <p>Welcome to your RS Saddles account page. Here you can view your upcoming and previous appointment details</p>

    <section class="customer-section">
      <h3>Upcoming Appointments</h3>

      <table mat-table [dataSource]="upcomingAppointments" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Date Column -->
        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef> Date/Time </th>
          <td mat-cell *matCellDef="let appt"> {{moment(appt.startTime).format('MMM YY HH:mm Do')}} </td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="fitter">
          <th mat-header-cell *matHeaderCellDef> Fitter </th>
          <td mat-cell *matCellDef="let appt"> {{appt.fitter.name}} </td>
        </ng-container>

        <!-- Horse Column -->
        <ng-container matColumnDef="horse">
          <th mat-header-cell *matHeaderCellDef> Horse </th>
          <td mat-cell *matCellDef="let appt"> {{appt.horse.name}} </td>
        </ng-container>

        <!-- Horse Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <mat-cell *matCellDef="let appt" >
              <button mat-flat-button  color="primary">Complete Details</button>
        </mat-cell>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="appointmentClicked(row)"></tr>
      </table>
      </section>

      <section class="customer-section">
        <h3>Previous Appointments</h3>
      <table mat-table [dataSource]="previousAppointments" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Date Column -->
        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef> Date/Time </th>
          <td mat-cell *matCellDef="let appt"> {{moment(appt.startTime).format('MMM YY HH:mm Do')}} </td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="fitter">
          <th mat-header-cell *matHeaderCellDef> Fitter </th>
          <td mat-cell *matCellDef="let appt"> {{appt.fitter.name}} </td>
        </ng-container>

        <!-- Horse Column -->
        <ng-container matColumnDef="horse">
          <th mat-header-cell *matHeaderCellDef> Horse </th>
          <td mat-cell *matCellDef="let appt"> {{appt.horse.name}} </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
      </table>

    </section>

  </div>
  </div>
