import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APIResponse } from '../models/api-response.model';
import { Observable } from 'rxjs';
import { UserAuthService } from '../auth/user-auth.service';
import { UserEndpoint } from './user-endpoint';
import { CookieService } from 'ngx-cookie-service';
import { CustomerEndpoint } from './customer-endpoint';
import { HorsesEndpoint } from './horses-endpoint';
import { AppointmentsEndpoint } from './appointments-endpoint';
import { AddressEndpoint } from './addresses-endpoint';

import { environment } from './../../environments/environment';
import { PaymentsEndpoint } from './payments-endpoint';
import { MeEndpoint } from './me-endpoint';
import { UtilityEndpoint } from './utility-endpoint';
import { RSFirebaseService } from '../services/rsfirebase.service';

@Injectable({
  providedIn: 'root'
})
export class APIServiceService {
  // host = 'http://localhost:3000/';
  // host = 'http://rsbookings.jellycathosting.co.uk/';
  host = environment.apiUrl;
  apiVersion = 1;
  baseURL = this.host + 'api/v' + this.apiVersion + '/';
  private userAuthService: UserAuthService;

  // ENDPOINTS
  user = new UserEndpoint(this);
  customer = new CustomerEndpoint(this);
  horses = new HorsesEndpoint(this);
  appointments = new AppointmentsEndpoint(this);
  addresses = new AddressEndpoint(this);
  payments = new PaymentsEndpoint(this);
  me = new MeEndpoint(this, this.rsAuth);
  utility = new UtilityEndpoint(this);


  constructor(
    private httpClient: HttpClient,
    private injector: Injector,
    private cookieService: CookieService,
    private rsAuth: RSFirebaseService
    ) {}




  getCurrentUserToken(): string {
    return this.cookieService.get('rs_auth_token');

  }

  getCurrentApptDetailsToken(): string {
    return this.cookieService.get(environment.CUSTOMER_APPOINTMENT_TOKEN_COOKIE_KEY);

  }



  doGET(url: string, token: string, params: any): Observable<any> {
    const args = {
      headers : {},
      params
    }

    if (token){
      args.headers = { 'x-access-token' : token }
    }

    return this.httpClient.get<APIResponse>(this.baseURL + url, args);
  }

  doPOST(url: string, token: string, body: any): Observable<any> {
    return this.httpClient.post<APIResponse>(this.baseURL + url, body, {
      headers : {
        'x-access-token' : token
      }
    });
  }

  doDELETE(url: string, token: string): Observable<any> {
    return this.httpClient.delete<APIResponse>(this.baseURL + url, {
      headers : {
        'x-access-token' : token
      }
    });
  }


  async doCustomerGET(url: string, params: any): Promise<any> {
    const args = {
      headers : {},
      params
    }

    const token = await this.rsAuth.getIdtoken();
      if (!token) {
        throw Error('id_token_not_available');

      }

      args.headers = { 'authorization' : 'Bearer ' + token }

    try{
    const response = await this.httpClient.get<APIResponse>(this.baseURL + url, args).toPromise();
    return response;
    } catch(err){
      throw Error(err);
    }
  }

  async doCustomerPOST(url: string, body: any): Promise<any>  {
    const token = await this.rsAuth.getIdtoken();
      if (!token) {
        throw Error('id_token_not_available');

      }
      try{
    const response = await this.httpClient.post<APIResponse>(this.baseURL + url, body, {
      headers : {
        'authorization' : 'Bearer ' + token
      }
      }).toPromise();
      return response;
      } catch(err){
        console.log(err)
        throw Error(err);
      }
  }

  async doUnauthPOST(url: string, body: any): Promise<any>  {

      try{
    const response = await this.httpClient.post<APIResponse>(this.baseURL + url, body, {
      headers : {

      }
      }).toPromise();
      return response;
      } catch(err){
        console.log(err)
        throw Error(err);
      }
  }

}
