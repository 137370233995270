import { Component, OnInit } from '@angular/core';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { RSFirebaseService } from 'src/app/services/rsfirebase.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'ubc-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  moment: any = moment;
  displayedColumns: string[] = ['startTime', 'fitter', 'horse', 'action'];
  displayedColumns2: string[] = ['startTime', 'fitter', 'horse'];
  upcomingAppointments = [];
  previousAppointments = [];
  constructor(
    private rsFirebase: RSFirebaseService,
    private apiService: APIServiceService,
    private router: Router) { }

  ngOnInit(): void {
    this.initDashboard();
  }

  async initDashboard(){

    const appointmentsResponse = await this.apiService.me.getAppointments();

    if(!appointmentsResponse.success) {
      return;
    }
    this.upcomingAppointments = appointmentsResponse.data.upcoming;
    this.previousAppointments = appointmentsResponse.data.previous;
  }

  appointmentClicked(row){

    this.router.navigateByUrl('booking/new/info/' + row.hashedId);
  }
}
