
<div class="appointment-summary">
  <div class="summary-inner">
    <div class="date">
      <span>{{appointment.startTime.format("ddd")}}</span>
      <span>{{appointment.startTime.format("D")}}</span>
      <span>{{appointment.startTime.format("MMM")}}</span>
    </div>
    <div class="time">
      <span>{{appointment.startTime.format("HH:mm")}}</span>
      <span>{{appointment.endTime.format("HH:mm")}}</span>
    </div>
    <div class="details">
      <div class="name" *ngIf="appointment.customer">
        {{appointment.customer.getFullName()}}
      </div>
      <div class="horse" *ngIf="appointment.horse">
          {{appointment.horse.name}}
        </div>
      <div class="address" *ngIf="appointment.address" [innerHTML]="appointment.address.getFullSummary(true)">

      </div>
    </div>
    <div class="status">
        <mat-chip-list aria-label="Color selection">
            <mat-chip  color="primary" *ngIf="appointment.type">{{appointment.type.name}}</mat-chip>
            <mat-chip  color="primary" *ngIf="appointment.budget">{{appointment.budget}}</mat-chip>
            <mat-chip  color="primary" *ngIf="appointment.fitter">{{appointment.fitter.name}}</mat-chip>
            <mat-chip  color="primary" *ngIf="appointment.saddleType">{{appointment.saddleType.name}}</mat-chip>
          </mat-chip-list>
    </div>
  </div>
  <div class="cancel-button" (click)="cancelClicked()">
    <fa-icon [icon]="faWindowClose"></fa-icon>
  </div>
</div>

<mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper (selectionChange)="stepperStepChanged($event)">

    <mat-step [completed]="appointment.customer" label="Customer">


<div class="column-form-section">
  <div class="form-col-left">

  </div>
  <div class="form-col-right">

  </div>

</div>


        <div class="column-form-section">

            <div class="form-col-right">
              <h6>Customer</h6>
              <mat-checkbox [(ngModel)]="appointmentIsProvisional">Create as provisional booking</mat-checkbox>
              <br>
              <mat-checkbox [(ngModel)]="sendDetailsForm">Require additional details from customer?</mat-checkbox>
              <mat-form-field appearance="outline" *ngIf="appointmentIsProvisional">
                <mat-label>Fitter</mat-label>
                <mat-select [(ngModel)]="appointment.fitter">
                  <mat-option *ngFor="let fitter of fitters" [value]="fitter">
                      {{ fitter.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
                <ubc-customer-picker
                *ngIf="!createCustomer"
                (customerPicked)="customerPicked($event)"
                (newCustomerClicked)="createCustomer = true"
                #customerPickerComponent></ubc-customer-picker>

                <ubc-create-customer
                *ngIf="createCustomer"
                (customerCreateCancelled)="customerCreateCancelled()"
                (customerCreated)="customerCreated($event)"
                ></ubc-create-customer>
            </div>

          </div>




          <div class="button-row">
            <div>&nbsp;</div>
            <button mat-raised-button color="primary" (click)="customerStageCompleteClicked()" *ngIf="!createCustomer" [disabled]="!appointment.customer">{{(appointmentIsProvisional) ? 'Create Provisional Booking' : 'Next'}}</button>
          </div>
    </mat-step>

    <mat-step [completed]="appointment.horse" label="Horse">

        <div class="column-form-section">

            <div class="form-col-right">
              <h6>Horse Details</h6>
                <div class="split-section">
                    <ubc-create-horse *ngIf="appointment.horse && !appointment.horse.id" [customer]="appointment.customer" (horseCreated)="newHorseCreated($event)" (cancelled)="newHorseCancelled()"></ubc-create-horse>
                    <ubc-horse-picker [customer]="appointment.customer" (horsePicked)="horsePicked($event)" #horsePickerComponent></ubc-horse-picker>
                </div>
                  <div class="button-row"   >
                      <button mat-button matStepperPrevious>Back</button>
                      <button mat-raised-button color="primary" matStepperNext [disabled]="!appointment.horse || !appointment.horse.id">Next</button>
                    </div>
            </div>

          </div>



      </mat-step>

      <mat-step [completed]="appointment.address" label="Address">

          <div class="column-form-section">

              <div class="form-col-right">
                <h6>Appointment address</h6>
                  <ubc-address-creator *ngIf="appointment.address && !appointment.address.id" [customer]="appointment.customer" (addressCreated)="addressCreated($event)" (cancelled)="addressCreateCancelled()"></ubc-address-creator>
          <ubc-address-picker *ngIf="!addressCreatorOpen" (cancelled)="addressPickerCancelled()" (addressPicked)="addressPicked($event)" [customer]="appointment.customer" #addressPickerComponent></ubc-address-picker>
              </div>

            </div>



        <div class="button-row">
            <button mat-button matStepperPrevious>Back</button>
            <button mat-raised-button color="primary" [disabled]="!appointment.address || !appointment.address.id" matStepperNext>Next</button>
          </div>
      </mat-step>

      <mat-step [completed]="false" label="Appointment">
        <ubc-appointment-details [appointment]="appointment" (detailsUpdated)="detailsUpdated($event)"></ubc-appointment-details>

        <!-- <div class="button-row">
            <button mat-button matStepperPrevious>Back</button>
          <button mat-raised-button color="primary" (click)="createAppointment()">Create Appointment</button>

         </div> -->

    </mat-step>

  </mat-horizontal-stepper>
