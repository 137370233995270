<div class="page">

<div class="customer-search">

  <div class="topbar">
    <div class="dummy">&nbsp;</div>
  <mat-form-field class="search-field-wrap">
    <mat-label><fa-icon [icon]="faSearch"></fa-icon> Search</mat-label>
    <input matInput #customerSearchInput placeholder="Customer name" autocomplete="off" />
  </mat-form-field>
  <div class="right"><button (click)="showSMSPanel = !showSMSPanel" class="btn">Send SMS</button></div>



</div>

<div id="table-wrap">
  <mat-progress-bar *ngIf="isLoadingCustomers" mode="indeterminate"></mat-progress-bar>
<table id="customers-table" mat-table [dataSource]="customersListDataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let customer"> {{customer.getFullName()}} </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let customer"> {{customer.email}} </td>
  </ng-container>

  <ng-container matColumnDef="tel">
    <th mat-header-cell *matHeaderCellDef> Tel </th>
    <td mat-cell *matCellDef="let customer"> {{customer.tel}} </td>
  </ng-container>

  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef> View </th>
    <td mat-cell *matCellDef="let customer"> <button (click)="viewCustomerClicked(customer.id)" mat-button color="primary">View</button> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr class="clickable-row" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="viewCustomerClicked(row.id)"></tr>
</table>


</div>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 50]" ></mat-paginator>

</div>

<div class="sms-modal" *ngIf="showSMSPanel">
  <div class="inner">
    <div class="close" (click)="cancelSMS()"><span class="material-icons">close</span></div>
  <div class="send-sms">
    <h3>Send SMS</h3>
    <p>Enter a phone number using international dialing code<br>and a message below to send an SMS</p>
    <div class="sms-form">
      <input type="text" [(ngModel)]="smsNumber"  placeholder="+441232123321"/>
      <br>
      <textarea [(ngModel)]="smsMessage" placeholder="Enter your message here"></textarea>
      <button class="btn"(click)="sendSMS()" [disabled]="smsSendInProgress">Send</button>
    </div>
  </div>
</div>
</div>


<!--
<button (click)="mode = 'createCustomer'">Create new customer</button>
<button (click)="mode = 'listCustomers'">List Customers</button>
<button (click)="createDummyAppointment()">Create dummy appointment</button>
<button (click)="deleteDummyAppointment()">Delete dummy appointment</button>
<button (click)="mode = 'manageAddresses'">Manage addresses</button>
<div *ngIf="mode === 'createCustomer'">
<h4>Create new customer</h4>

<form [formGroup]="createCustomerForm" (ngSubmit)="createCustomerSubmit()">
    <label>First Name: </label>
    <input type="text" formControlName="firstName"/>
    <br>
    <label>Last Name: </label>
    <input type="text" formControlName="lastName"/>
    <br>
    <label>Email Name: </label>
    <input type="email" formControlName="email"/>
    <br>
    <label>Telephone: </label>
    <input type="tel" formControlName="telephone"/>
    <br>
    <hr>
    <label>Address Line 1: </label>
    <input type="text" formControlName="billingAddress1"/>
    <br>
    <label>Address Line 2: </label>
    <input type="text" formControlName="billingAddress2"/>
    <br>
    <label>Town: </label>
    <input type="text" formControlName="billingTown"/>
    <br>
    <label>City: </label>
    <input type="text" formControlName="billingCity"/>
    <br>
    <label>Postcode: </label>
    <input type="text" formControlName="billingPostcode"/>
    <br>
    <hr>
    <label>Notes: </label>
    <textarea formControlName="notes"></textarea>
    <br>

    <button type="submit" [disabled]="createCustomerForm.invalid">Create Customer</button>
  </form>

</div>



<div *ngIf="mode === 'editCustomer'">
  <h4>Editing customer</h4>
  Name: {{ selectedCustomer.firstName}} {{selectedCustomer.lastName }}
  <br>
  Email: {{ selectedCustomer.email }}
  <br>
  <hr>
  <h5>Horses</h5>
  <div *ngFor="let horse of selectedCustomerHorses">
    {{ horse.name }}
    <button (click)="dummyEditHorse(horse)">Edit Horse</button>
    <button (click)="deleteHorse(horse)">Delete Horse</button>
  </div>
  <hr>
  <h6>Create new Horse</h6>
  <form [formGroup]="createHorseForm" (ngSubmit)="createHorseSubmited()">
      <label>Name: </label>
      <input type="text" formControlName="name"/>
      <br>
      <label>Height: </label>
      <input type="number" formControlName="height"/>
      <br>
      <label>Age: </label>
      <input type="number" formControlName="age"/>
      <br>
      <label>Breed: </label>
      <input type="text" formControlName="breed"/>
      <br>
      <label>Sex: </label>
      <input type="text" formControlName="sex"/>
      <br>
      <div formGroupName="disciplines">
        <h5>Disciplines</h5>

        <div *ngFor="let discipline of getDisciplines()">
            <label>{{ discipline.title }} </label>
            <input  type="checkbox"  formControlName="{{ discipline.name }}" />
            <br>
        </div>
      </div>

      <br>
      <label>Affiliated?: </label>
      <input  type="checkbox" formControlName="isAffiliated"/>
      <br>
      <label>Colour: </label>
      <input type="text" formControlName="colour"/>
      <br>
      <button type="submit" [disabled]="createHorseForm.invalid">Create Horse</button>
  </form>
</div>

<div *ngIf="mode === 'manageAddresses'">
  <h3>Addresses</h3>
  <form [formGroup]="createAddressForm" (ngSubmit)="submitAddressClicked()">
      <label>Address 01: </label>
      <input type="text" formControlName="line1"/>
      <br>

      <label>Address 02: </label>
      <input type="text" formControlName="line2"/>
      <br>

      <label>Town: </label>
      <input type="text" formControlName="town"/>
      <br>

      <label>City: </label>
      <input type="text" formControlName="city"/>
      <br>

      <label>Postcode: </label>
      <input type="text" formControlName="postcode"/>
      <br>

      <label>Country: </label>
      <input type="text" formControlName="country"/>
      <br>

      <label>Notes: </label>
      <input type="text" formControlName="notes"/>
      <br>
      <button type="submit" [disabled]="createHorseForm.invalid">Create Address</button>

  </form>
</div>

-->

</div><!--end .page -->
