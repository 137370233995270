export default class AppError {

    public key: string;
    public code: string;
    public message: string;

    constructor(key: string, code: string, message?: string){
        this.key = key;
        this.code = code;
        this.message = message;
    }

}
