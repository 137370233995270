import { APIResponse } from '../models/api-response.model';
import { APIServiceService } from './apiservice.service';
import { Horse } from '../models/horse.model';
import { Observable } from 'rxjs';

export class HorsesEndpoint {

  constructor(private apiService: APIServiceService) {}
  endpoint = 'horses/';

  create(horse: Horse): Promise<{success: boolean, data: Horse}> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST(this.endpoint, token, horse).subscribe(response => resolve(response));
    });
  }

  patch(horseID: number, detailsToUpdate: any): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST(this.endpoint + 'patch/' + horseID, token, detailsToUpdate).subscribe(response => resolve(response));
    })
  }

  update(horse: Horse): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST(this.endpoint + horse.id, token, horse).subscribe(response => resolve(response));
    });
  }

  delete(horseId: number): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doDELETE(this.endpoint + horseId, token).subscribe(response => resolve(response));
    });
  }

  list(page?: number, size?: number): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint, token, { page, size }).subscribe(response => resolve(response));
    });
  }



  getByID(id: number): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + id, token, null).subscribe(response => resolve(response));
    });
  }
}
