

<mat-selection-list  #addressSelect>
    <mat-list-option *ngFor="let address of addressResults" [value]='address' [disableRipple]="true">

      <p matLine class="customer-address">
        <span [innerHTML]="address.getFullSummary(true)"></span>
        <button class="delete-button" color="warn" mat-icon-button (click)="deleteAddressClicked(address.id)"><mat-icon>delete</mat-icon></button>
      </p>

    </mat-list-option>

    <mat-list-option [value]="'new_address'">
        <mat-icon matListIcon>add</mat-icon>
        <p matLine class="customer-address">
          <span>New Address</span>
        </p>
      </mat-list-option>

  </mat-selection-list>

