import { Injectable } from '@angular/core';
import {
  AuthService,
  GoogleLoginProvider,
  SocialUser
} from 'angularx-social-login';
import { APIServiceService } from '../api/apiservice.service';
import { Router } from '@angular/router';
import { APIResponse } from '../models/api-response.model';
import { User } from '../models/user.model';
import { CookieService } from 'ngx-cookie-service';
import ErrorsLibrary from '../errors/errors-library';
import AppError from '../errors/error';


@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  AUTH_TOKEN_COOKIE_KEY = 'rs_auth_token';
  user: User;
  loggedIn: boolean;

  constructor(
    private socialAuthService: AuthService,
    private apiService: APIServiceService,
    private router: Router,
    private cookieService: CookieService) {
      // On initial load, check if we have a cookie of a previous access token, if so attempt to verify it
      const savedToken = this.cookieService.get(this.AUTH_TOKEN_COOKIE_KEY);
      if (savedToken) {
        console.log('Validating saved token');
        this.checkAccessToken(savedToken);
      }

    }

    // subscribeToAuthStateChanges() {
    //   this.socialAuthService.authState.subscribe((user) => {
    //     console.log('User Auth State Changed');
    //     if(!user){
    //        this.signOut();
    //     }
    //   });
    // }

    // ****************************
    // ****** GOOGLE LOGIN ********
    // ****************************

    signInWithGoogle(): Promise<any | AppError> {
      return new Promise((resolve, reject) => {
        const socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
        let authData: SocialUser;
        this.socialAuthService.signIn(socialPlatformProvider).then(
          (userData: SocialUser) => {
            if(userData.idToken) {
              authData = userData;
              this.loginWithGoogleToken(userData.idToken)
              .then((response) => {
                resolve(response)
              })
              .catch((err) => { reject(err) });
            } else {
              //  Google sign in failed
              reject(ErrorsLibrary.login.googleLoginFailed);
            }


          }
        );
      })

    }

    loginWithGoogleToken(token: string): Promise<any | AppError> {
      return new Promise((resolve, reject) => {

        this.apiService.user.login(token)
          .then((response: APIResponse) => {
            if (response.success) {
              this.cookieService.set(this.AUTH_TOKEN_COOKIE_KEY, response.data.jwtToken, 30);
              this.user = new User().deserialize(response.data.user);
              this.loggedIn = true;
              this.router.navigate(['/calendar'],{ replaceUrl: true });
              resolve(this.user);
            } else {
              this.signOut();
              reject(ErrorsLibrary.login.userNotFound);
            }
          })
          .catch((err) => {
            this.signOut();
            reject(ErrorsLibrary.login.loginError);
          });
      });

    }

    // ****************************
    // **** GOOGLE LOGIN END ******
    // ****************************

    // *********************************
    // ** ACCESS TOKEN BASED AUTH ******
    // *********************************

    checkAccessToken(token) {
      this.apiService.user.authenticateToken(token)
      .then((response) => {
        if (response.success) {
          this.user = new User().deserialize(response.data.user);
          this.loggedIn = true;
        } else {
          this.user = null;
          this.loggedIn = null;
        }
      })
      .catch((err) => { console.log(err) });
    }

    // loginWithAcccessToken(token: string) {
    //   this.apiService.user.authenticateToken(token)
    //   .then((response) => {
    //     if (response.success) {
    //       this.user = new User().deserialize(response.data.user);
    //       this.loggedIn = true;
    //       this.router.navigate(['/home']);
    //     }
    //   })
    //   .catch((err) => { console.log(err) });
    // }

    // *********************************
    // ** ACCESS TOKEN BASED AUTH END **
    // *********************************


    signOut(): void {
      console.log('signing user out');
      this.cookieService.delete(this.AUTH_TOKEN_COOKIE_KEY);
      this.user = null;
      this.loggedIn = false;
      this.router.navigate(['/']);
    }
}
