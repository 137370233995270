import { IDeserializable } from './deserializable.model';

export class Address implements IDeserializable {

  public id: number;
  public line1: string;
  public line2: string;
  public town: string;
  public city: string;
  public postcode: string;
  public country: string;
  public lat: number;
  public lng: number;
  public notes: string;
  public customerId: number;


  public getAddressSummary(singleLine?: boolean) {
    const joiner = (singleLine) ? ', ' : '<br>';
    let out = (this.line1) ? this.line1 : '';
    out += (this.town) ? joiner + this.town : '';
    return out;
  }

  public getFullSummary(singleLine?: boolean) {
    const joiner = (singleLine) ? ', ' : '<br>';
    let out = (this.line1) ? this.line1 : '';
    out += (this.line2) ? joiner + this.line2  : '';
    out += (this.town) ? joiner + this.town : '';
    out += (this.city) ? joiner + this.city : '';
    out += (this.postcode) ? joiner + this.postcode : '';
    return out;
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }



}
