export class AppointmentType {
  id: number;
  name: string;
  abbreviation: string;

  constructor(i: number, n: string) {
    this.id = i;
    this.name = n;
    const words = this.name.split(" ")
    this.abbreviation = ""
    for(let index in words){
      this.abbreviation += words[index].charAt(0)
    }
  }
}
