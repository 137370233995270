import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

import { RSFirebaseService } from 'src/app/services/rsfirebase.service';

@Component({
  selector: 'ubc-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class PublicHeaderComponent implements OnInit, AfterViewInit {
  constructor(public auth: AngularFireAuth, public authService: RSFirebaseService, private router: Router) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(){

  }

  async signOut(){
    try {
      const loggedOut = await this.auth.signOut();
      this.router.navigateByUrl('login')
    } catch(err){
      console.log('Error while loggin user out ', err);
    }


  }

  goToMyAccount(){
    this.router.navigateByUrl('my-account');
  }
}
