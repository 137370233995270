import { APIResponse } from '../models/api-response.model';
import { APIServiceService } from './apiservice.service';
import { Observable, observable } from 'rxjs';
import { AppointmentAPIBody } from '../models/appointment-api-body.model';
import { RSFirebaseService } from '../services/rsfirebase.service';

export class MeEndpoint {
  endpoint = 'me/';
  constructor(private apiService: APIServiceService, private rsAuth: RSFirebaseService) {}

  async getAppointments(): Promise<APIResponse> {
      const response = await this.apiService.doCustomerGET(this.endpoint + 'appointments/upcoming/list', {});
      return response;
  }

  async getHorses(): Promise<APIResponse> {
    const response = await this.apiService.doCustomerGET(this.endpoint + 'horses/list', {});
    return response;
}

async getAddresses(): Promise<APIResponse> {
  const response = await this.apiService.doCustomerGET(this.endpoint + 'addresses/list', {});
  return response;
}

  async getProvisionalAppointment(bookingId: string): Promise<APIResponse> {
    const response = await this.apiService.doCustomerGET(this.endpoint + 'provisional/' + bookingId, {});
    return response;
  }

  async getResponsesForProvisionalAppointment(hashedBookingId: string): Promise<APIResponse> {

    const response = await this.apiService.doCustomerPOST(this.endpoint + '/access/responses', { hashedBookingId })
   return response;

 }

  async updateResponsesForBooking(hashedBookingId: string, responses: string): Promise<APIResponse> {
      const response = await this.apiService.doCustomerPOST(this.endpoint + '/provisional/responses', { responses, hashedBookingId });
      return response;

  }

  async isEmailtaken(email: string): Promise<APIResponse> {
    const response = await this.apiService.doUnauthPOST(this.endpoint + '/email', { email });
    return response;

}



}
