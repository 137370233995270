import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Customer } from 'src/app/models/customer.model';

export interface IProvisionalBookingDetails{
  name: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
  email: string;
  price: number;
  customer?: Customer;
  depositPaymentId?: string;
}

@Component({
  selector: 'ubc-provisional-booking',
  templateUrl: './provisional-booking.component.html',
  styleUrls: ['./provisional-booking.component.scss']
})
export class ProvisionalBookingComponent implements OnInit {

  @Input() bookingDetails: IProvisionalBookingDetails;
  @Input() hidePrice = true;
  constructor() { }

  ngOnInit(): void {
  }

}
