import AppError from './error';

export class ErrorsLibrary {
    // Customers
    public login = {
        googleLoginFailed     : new AppError('googleLoginFailed', 'L001', 'Google login failed'),
        userNotFound     : new AppError('userNotFound', 'L002', 'User not found'),
        loginError     : new AppError('loginError', 'L003', 'Login error')
    }

}
export default new ErrorsLibrary();
