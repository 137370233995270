<nav id="main-nav" [ngClass]="{'mobile-open': mobileMenuOpen }">
  <span class="nav-back" (click)="goBack()" *ngIf="!backDisabled"><fa-icon [icon]="faChevronLeft"></fa-icon></span>
  <div class="nav-inner">
      <a
      *ngFor="let page of pages"
      [ngClass]="{'hidden-on-mobile' : pageIsCurrent(page), 'active' : !pageIsCurrent(page)}"
      (click)="navigateToPage(page)">
      {{ page.title }}</a>
  </div>

  <!--<div id="current-page-title">{{ currentPageTitle }}</div>-->

  <div id="user-details">
     {{ userAuthService.user.getName() }}
      <br>
      <span (click)="signOut()">Sign Out</span>
  </div>

  <div id="menu-toggle" (click)="mobileMenuToggle()">
      <i *ngIf="mobileMenuOpen" class="icon ion-md-close"></i>
      <i *ngIf="!mobileMenuOpen" class="icon ion-md-menu"></i>
  </div>

</nav>


