<div class="public-page-wrap">
  <div class="container" >
    <ubc-public-header></ubc-public-header>

    <div class="login-box-wrap" *ngIf="!(auth.user | async)">
      <div class="login-box">
        <div *ngIf="!loginEmailSent">
          <h2>Login</h2>
          <p>Please enter your email and we will send you a login link</p>
          <input type="email" [(ngModel)]="email" placeholder="Your email address"/>
          <div class="invalid-email-notice" *ngIf="emailNotRecognized">Sorry, this email address is not recognized</div>
          <button class="btn" [disabled]="!email" (click)="sendLoginEmail()" >Send login link</button>
        </div>
        <div *ngIf="loginEmailSent">
          <h2>Login</h2>
          A login link has been sent to {{email}}. Please click the link in the email to log in
          <button class="btn" [disabled]="!email" (click)="sendLoginEmail()">Resend Email</button>
        </div>
      </div>
    </div>

    <div *ngIf="(auth.user | async)">USER IS LOGGED IN</div>

  </div>
