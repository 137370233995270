import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Address } from 'src/app/models/address.model';
import { Customer } from 'src/app/models/customer.model';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { MatListOption, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import Swal from 'sweetalert2';

@Component({
  selector: 'ubc-address-picker',
  templateUrl: './address-picker.component.html',
  styleUrls: ['./address-picker.component.scss']
})
export class AddressPickerComponent implements OnInit, AfterViewInit {
  @Input() customer: Customer;
  @Output() addressPicked = new EventEmitter();
  @Output() cancelled = new EventEmitter();
  constructor(private apiService: APIServiceService) { }

  addressResults: Address[] = [];
  @ViewChild('addressSelect', {}) addressSelect: MatSelectionList;
  deleteClicked = false;

  ngAfterViewInit() {

    this.getAddresesForCustomer(this.customer);

    this.addressSelect.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.addressSelect.deselectAll();
      if(this.deleteClicked) { this.deleteClicked = false; return }

      const option: MatListOption = s.option;

      if (option.value !== 'new_address') {
        s.option.selected = true;
        this.addressPicked.emit(option.value);
      } else {
        this.addressPicked.emit(new Address());
      }
    });
  }

  ngOnInit() {

  }

  getAddresesForCustomer(customer?: Customer) {
    if (customer) {
      this.customer = customer;
    } else {
      return;
    }
    this.apiService.customer.getAddresses(this.customer.id)
    .then((response) => {
      if (response.success) {
        this.addressResults = response.data.results;
      }
    })
    .catch((err) => { console.log(err)});
  }

  deleteAddressClicked(addressId) {
    this.deleteClicked = true;
    Swal.fire({
      icon: 'warning',
      title : 'Are you sure you would like to delete this address?',
      showCancelButton: true })
    .then((dismissed) => {
      if (dismissed.isConfirmed){
        this.apiService.customer.deleteAddress(this.customer.id, addressId)
        .then((response) => {
          this.getAddresesForCustomer(this.customer);
        })
        .catch((err) => { console.log(err)});
      }
    })
    .catch((err) => { console.log(err)});
  }




}
