import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserAuthService } from './user-auth.service';
import { APIServiceService } from '../api/apiservice.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private cookieService: CookieService, private apiService: APIServiceService){}

  canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean>{
    return new Promise((resolve, reject) => {
      const token = this.cookieService.get('rs_auth_token');
      if (!token) {
        reject(false);
        return;
      }
      this.apiService.user.authenticateToken(token)
      .then((response) => {
        resolve(true);
      })
      .catch((err) => {
        reject(false); });
    });
  }


}
