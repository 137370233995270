<div class="customer-search-wrap">
<mat-form-field appearance="outline">
  <input matInput type="text" #customerSearchInput placeholder="Search customer name..." autocomplete="off">
</mat-form-field>
<button (click)="newCustomerButtonClicked()" mat-mini-fab color="primary"><i class="icon ion-md-add"></i></button>
</div>
<mat-progress-bar *ngIf="searchInProgress" mode="indeterminate"></mat-progress-bar>
<mat-selection-list  #customerSelect>
  <mat-list-option *ngFor="let c of searchResults" [value]='c'>
    <mat-icon matListIcon>perm_identity</mat-icon>
    <h3 matLine> {{c.getFullName()}} </h3>
    <p matLine class="customer-address">
      <span> {{c.email}} </span>
      <br>
      <span [innerHTML]="c.getAddressSummary(true)"></span>
    </p>
  </mat-list-option>
</mat-selection-list>


<!-- <mat-grid-list cols="2" [rowHeight]="'4:1'" >
    <mat-grid-tile *ngFor="let c of searchResults">
    <mat-card >
        <mat-card-title-group>
            <mat-card-title>{{ c.getFullName() }}</mat-card-title>
            <mat-card-subtitle>{{ c.email }}</mat-card-subtitle>
        </mat-card-title-group>
        <mat-card-content>
            <p>
              {{c.billingTown}}
              <br>
              {{c.tel}}
            </p>
        </mat-card-content>
        <mat-card-actions>
            <button (click)="selectCustomer(c)" mat-button>SELECT</button>
          </mat-card-actions>
    </mat-card>
    </mat-grid-tile>
  </mat-grid-list> -->





