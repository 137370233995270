import { IDeserializable } from './deserializable.model';
export class SaddleType {
  public id: number;
  public name: string;

  constructor(i?: number, n?: string){
    if (!i || !n) {
      return;
    }
    this.id = i;
    this.name = n;
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
