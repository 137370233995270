import { APIResponse } from '../models/api-response.model';
import { APIServiceService } from './apiservice.service';
import { Observable, observable } from 'rxjs';
import { AppointmentAPIBody } from '../models/appointment-api-body.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export class AppointmentsEndpoint {
  endpoint = 'appointments/';
  constructor(private apiService: APIServiceService) {}



  create(appointmentBody: AppointmentAPIBody): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST(this.endpoint, token, appointmentBody).subscribe(response => resolve(response));
    });
  }

  patch(appointmentID: number, detailsToUpdate: any): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST(this.endpoint + 'patch/' + appointmentID, token, detailsToUpdate).subscribe(response => resolve(response));
    })
  }

  delete(appointmentId: any): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doDELETE(this.endpoint + appointmentId, token).subscribe(response => resolve(response));
    });
  }

  getByID(appointmentId: number): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + appointmentId, token, {}).subscribe(response => resolve(response));
    });
  }

  getProvisional(appointmentHashedId: string): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentApptDetailsToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + '/provisional/' + appointmentHashedId, token, {}).subscribe(response => resolve(response));
    });
  }

  getWithinDates(startDate: string, endDate: string, splitByDate: boolean): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint, token, { startDate, endDate, splitByDate }).subscribe(response => resolve(response));
    });
  }

  getTypes(): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + "/types", token, {}).subscribe(response => resolve(response));
    });
  }

  updateStartEndTimes(appointmentId: number, startTime: string, endTime: string): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST(this.endpoint + '/patch/' + appointmentId, token, { startTime, endTime }).subscribe(response => resolve(response));
    });
  }

  requestAccessSMSForBookingID(appointmentId: string): Promise<APIResponse> {
    return new Promise((resolve, reject) => {

      this.apiService.doPOST(this.endpoint + '/access/sms/', "", { appointmentId }).subscribe(response => resolve(response));
    });
  }

  confirmAccessSMSForBookingID(appointmentId: string, code: string): Promise<APIResponse> {
    return new Promise((resolve, reject) => {

      this.apiService.doPOST(this.endpoint + '/access/sms/confirm', "", { appointmentId, code }).subscribe(response => resolve(response));
    });
  }







}
