import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/auth/user-auth.service';
import { Router, ActivatedRoute, RoutesRecognized, ChildActivationEnd, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location, LocationStrategy } from '@angular/common';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export class Page {
  title: string;
  path: string;
  constructor(t: string, p: string){
    this.title = t;
    this.path = p;
  }
}

@Component({
  selector: 'ubc-logged-in-nav',
  templateUrl: './logged-in-nav.component.html',
  styleUrls: ['./logged-in-nav.component.scss']
})
export class LoggedInNavComponent implements OnInit {
  currentPageUrl: string;
  mobileMenuOpen = false;
  pages: Page[] = [
    new Page('CALENDAR', '/calendar'),
    //new Page('USERS', '/users'),
    new Page('CUSTOMERS', '/customers')
  ];

  constructor(
    public userAuthService: UserAuthService,
    private router: Router,
    private location: Location,
    locStrategy: LocationStrategy
    ) {
      // locStrategy.onPopState(() => {
      //   console.log('POPPED');

      //   return false;
      // })
    }

  faChevronLeft = faChevronLeft;
  backDisabled = false;
  ngOnInit() {
    this.subscribeToNavChanges();
    if (location.pathname === '/' && this.userAuthService.loggedIn) {
      this.router.navigateByUrl('/calendar');
    }
  }

  signOut() {
    this.userAuthService.signOut();
  }

  mobileMenuToggle() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  subscribeToNavChanges() {
    this.router.events.pipe(
      filter(event => (event instanceof ChildActivationEnd || event instanceof NavigationStart))

      ).subscribe((event: any) => {
          console.log('Nav event ', event);
          if ( event instanceof ChildActivationEnd) {
            // this.currentPageUrl = event.snapshot.routerState.url;
            // this.mobileMenuOpen = false;
            // this.backDisabled = (this.currentPageUrl === '/calendar');
          } else {
            // console.group( "NavigationStart Event" );

            // Every navigation sequence is given a unique ID. Even "popstate"
            // navigations are really just "roll forward" navigations that get
            // a new, unique ID.
            // console.log( "navigation id:", event.id );
            // console.log( "route:", event.url );
            // The "navigationTrigger" will be one of:
            // --
            // - imperative (ie, user clicked a link).
            // - popstate (ie, browser controlled change such as Back button).
            // - hashchange
            // --
            // NOTE: I am not sure what triggers the "hashchange" type.
            // console.log( "trigger:", event.navigationTrigger );


            // This "restoredState" property is defined when the navigation
            // event is triggered by a "popstate" event (ex, back / forward
            // buttons). It will contain the ID of the earlier navigation event
            // to which the browser is returning.
            // --
            // CAUTION: This ID may not be part of the current page rendering.
            // This value is pulled out of the browser; and, may exist across
            // page refreshes.
            if ( event.restoredState ) {

              // console.warn(
              //  "restoring navigation id:",
              //  event.restoredState.navigationId
              // );

            }

            // console.groupEnd();
          }
    });

  }

  goBack(){
    // console.log('Back clicked');
    this.location.back();
  }

  pageIsCurrent(page: Page): boolean {
    return (!this.mobileMenuOpen && (this.currentPageUrl !== page.path));
  }

  navigateToPage(page: Page) {
    if(this.currentPageUrl === page.path) {
      return;
    }
    this.router.navigateByUrl(page.path);
  }

}
