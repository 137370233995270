<div class="non-auth-header">
  <img class="logo" src="../assets/svg/logo.svg" />
  <div class="signed-in-header-right" *ngIf="authService.authState">


    <div (click)="signOut()" class="material-icons link">logout</div>
    <div class="menu">
      <span class="link" (click)="goToMyAccount()">My Account</span>
    </div>
    {{authService.authState.email}}<br>


  </div>
</div>
