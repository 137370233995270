import { APIResponse } from '../models/api-response.model';
import { APIServiceService } from './apiservice.service';
import { Observable, observable } from 'rxjs';
import { AppointmentAPIBody } from '../models/appointment-api-body.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export enum PaymentIntentType {
  BOOKING = 'booking'
}

export class PaymentsEndpoint {
  endpoint = 'payments/';
  constructor(private apiService: APIServiceService) {}



  createIntent(intentType: PaymentIntentType, hashedAppointmentId: string): Promise<APIResponse> {
    return new Promise((resolve, reject) => {

      this.apiService.doPOST(this.endpoint + '/intent', "", {intentType, hashedAppointmentId}).subscribe(response => resolve(response));
    });
  }





}
