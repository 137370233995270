import { APIResponse } from '../models/api-response.model';
import { APIServiceService } from './apiservice.service';
import { User } from '../models/user.model';
import { Observable, observable } from 'rxjs';

export class UserEndpoint {

  constructor(private apiService: APIServiceService) {}

  login(token: string): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService.doGET('user/login', token, false).subscribe(response => resolve(response));
    });
  }

  authenticateToken(token: string): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService.doGET('user/authenticate/token', token, false).subscribe(response => resolve(response));
    });
  }

  create(user: User): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST('user', token, user).subscribe(response => resolve(response));
    });
  }

  isEmailtaken(email: string): Observable<any> {
    return new Observable((obs) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        obs.error('no_auth_token');
        return;
      }
      this.apiService.doGET('user/email', token, { email }).subscribe(response => { obs.next(response); obs.complete();} );
    });
  }

  getEmailAvailability(email: string): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET('user/email', token, { email }).subscribe(response => resolve(response));
    });
  }

  getGCalEventsWithinDates(startDate: string, endDate: string): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET('user/gcalevents', token, {startDate, endDate}).subscribe(response => resolve(response));
    });
  }

  getMeta(metaKey: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET('user/meta', token, { metaKey }).subscribe(response => resolve(response));
    });
  }

}
