import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/auth/user-auth.service';
import { APIServiceService } from 'src/app/api/apiservice.service';
import AppError from 'src/app/errors/error';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  signInErrorMessage: string;

  ngOnInit() {
    console.log("URL: ", environment.apiUrl)
  }

  constructor(private userAuthService: UserAuthService, private apiService: APIServiceService) {}

  signInWithGoogle(): void {
    this.userAuthService.signInWithGoogle()
    .then((response) => {

    })
    .catch((err: AppError) => {
      this.signInErrorMessage = err.message;
      setTimeout(() => {
        this.signInErrorMessage = '';
      }, 5000);
    });

  }

  signOut(): void {
   this.userAuthService.signOut();
  }


}
