<div class="page">
  <div class="horse-details-section">

  <ubc-appointment-summary *ngIf="showAppointmentData && appointment" [appointment]="appointment"></ubc-appointment-summary>

  <div class="flex-row" [ngClass]="{'show-horse-switcher' : showHorseSwitcher}">


    <div class="horse-switcher-wrap" *ngIf="showHorseSwitcher">
      <h3>Select a horse</h3>
      <ubc-horses-table
      [buttonText]="'Select'"
      [customer]="customer"
      [customersHorsesDataSource]="customersHorsesDataSource"
      (horseButtonClicked)="horseFromTableClicked($event)"
      [displayedColumns]="['name','height', 'view']"
      ></ubc-horses-table>
    </div>

  <div class="horse-update-wrap">
    <h3>Edit horse details</h3>
    <ubc-details-table-form

  *ngIf="horsesDetailsForm"
  [hideHeader]="true"
  [formGroup]="horsesDetailsForm"
  [tables]="tables"
  (editClickedEmitter)="editClicked($event)"
  (formSubmittedEmitter)="horseDetailsSubmitted()"></ubc-details-table-form>

</div>
</div>

</div>
<div class="horse-details-section">

<ubc-create-horse *ngIf="customer" [customer]="customer" (horseCreated)="newHorseCreated($event)" (cancelled)="horseCreateCancelled($event)"></ubc-create-horse>
</div>




</div>
