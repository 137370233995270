import { Injectable } from '@angular/core';
import { AppointmentType } from '../models/appointment-type.model';
import { SaddleType } from '../models/saddle-type.model';
import { resolve } from 'url';
import { Fitter } from '../models/fitter.model';
import { APIServiceService } from '../api/apiservice.service';

export class AllEntities{
  fitters: Fitter[];
  appointmentTypes: AppointmentType[];
  saddleTypes: SaddleType[];
}

@Injectable({
  providedIn: 'root'
})
export class EntityTypesService {

  constructor(private apiService: APIServiceService) { }

  getAppointmentTypes(): Promise<AppointmentType[]> {
    return new Promise((res, reject) => {
      this.apiService.appointments.getTypes()
      .then((types)=>{
        const mappedTypes = types.data.map((type)=>{
          return new AppointmentType(type.id, type.name)
        })
        res(mappedTypes)
      })
      .catch((e)=>{
        reject(e);
      })
      // res( [
      //   new AppointmentType(8, 'Saddle refit'),
      //   new AppointmentType(9, 'Saddle delivery'),
      //   new AppointmentType(11, 'Bridle fitting'),
      //   new AppointmentType(12, 'New saddle'),
      //   new AppointmentType(13, 'Provisional'),
      //   new AppointmentType(14, 'Saddle refit (possibly new)')
      // ]);
    });

  }

  getSaddleTypes(): Promise<SaddleType[]> {
    return new Promise((res, reject) => {
      res([
        new SaddleType(1, 'Dressage Saddle'),
        new SaddleType(2, 'General Purpose Saddle'),
        new SaddleType(3, 'Jumping saddle')
      ]);
    });
  }

  getFitters(): Promise<Fitter[]> {
    return new Promise((res, reject) => {
      res([
        new Fitter(1, 'Ruth'),
        new Fitter(2, 'Vicky'),
        new Fitter(3, 'Both')
      ])
    });
  }

  getAllEntities(): Promise<AllEntities> {
    return new Promise((res, reject) => {
      const out: AllEntities = new AllEntities();
      this.getAppointmentTypes()
      .then((appointmentTypes) => {
        out.appointmentTypes = appointmentTypes;
        return this.getSaddleTypes();
      })
      .then((saddleTypes) => {
        out.saddleTypes = saddleTypes;
        return this.getFitters();
      })
      .then((fitters) => {
        out.fitters = fitters;
        res(out);
      })
      .catch((err) => { console.log(err); reject('Error getting all entities'); });
    });
  }

}
