import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faTimes, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

@Component({
  selector: 'ubc-details-table-form',
  templateUrl: './details-table-form.component.html',
  styleUrls: ['./details-table-form.component.scss']
})
export class DetailsTableFormComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Output() formSubmittedEmitter = new EventEmitter();
  @Input() tables: [];
  @Output() editClickedEmitter = new EventEmitter();
  @Output() itemEditClickedEmitter = new EventEmitter();
  @Input() hideHeader = false;
  @Output() deleteClicked = new EventEmitter();
  @Input() showDeleteButton = false;

  faTimes = faTimes;
  faCheck = faCheck;
  faEdit = faEdit;

  constructor() { }


  ngOnInit() {
  }

  formSubmittedClicked() {
    this.formSubmittedEmitter.emit(null);
  }

  editClicked(clickAction) {
    this.editClickedEmitter.emit(clickAction);
  }

  itemEditClicked(clickAction) {
    this.itemEditClickedEmitter.emit(clickAction);
  }

  deleteBookingClicked() {
    Swal.fire({
      icon: 'warning',
      title : 'Are you sure you would like to delete this booking?',
      showCancelButton: true })
    .then((dismissed) => {
      if (dismissed.isConfirmed){
        this.deleteClicked.emit();
      }
    })
    .catch((err) => { console.log(err) });
  }

}
