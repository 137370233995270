import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ubc-horses-table',
  templateUrl: './horses-table.component.html',
  styleUrls: ['./horses-table.component.scss']
})
export class HorsesTableComponent implements OnInit {
  @Input() buttonText = 'View';
  @Input() customer: Customer;
  @Input()customersHorsesDataSource = new MatTableDataSource([]);
  @Output() horseRowClicked = new EventEmitter();
  @Output() horseButtonClicked = new EventEmitter();
  @Input() displayedColumns: string[] = [ 'name', 'breed', 'colour', 'height', 'age', 'sex', 'notes', 'view'];



  constructor() { }

  ngOnInit(): void {
  }

  horseRowWasClicked(horseId) {
    this.horseRowClicked.emit(horseId);
  }

  horseButtonWasClicked(horseId) {
    this.horseButtonClicked.emit(horseId);
  }

}
