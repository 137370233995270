import { IDeserializable } from './deserializable.model';

export class User implements IDeserializable {

  id: number;
  firstName: string;
  lastName: string;
  email: string;
  authType: string;
  lastLogin: string;

  public getName() {
    return `${this.firstName} ${this.lastName}`;
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
