 <!-- <div *ngIf="appointment === null">
START: <input [(ngModel)]="startTime" /><br>{{startTime}}
<br>
END: <input [(ngModel)]="endTime" /><br>{{endTime}}
<br>
<button (click)="startCreateAppointmentClicked()">Start Create appointment</button>
</div> -->

<div class="calendar-page-wrap">


<div class="appointment-creator-wrap" [ngClass]="{'open' : appointment !== null}">
  <ubc-appointment-creator
  *ngIf="appointment !== null"
  [appointment]="appointment"
  (wasCancelled)="cancelCreateAppointmentClicked()"
  (wasCreated)="appointmentWasCreated()"
  ></ubc-appointment-creator>
</div>

<section class="calendar-section" [ngClass]="{'reduced' : appointment !== null}">


  <div class="calendar-wrap">
    <full-calendar #calendar
      [header]="{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      }"
      nowIndicator="true"
      aspectRatio = "calendarRatio"
      [businessHours]="businessHours"
      deepChangeDetection ="true"
      (datesRender) = "viewWasRendered()"
      [events] = "calendarEvents"
      (eventRender)="calendarEventRender($event)"
      defaultView="dayGridMonth"
      [plugins]="calenderPlugins"
      (dateClick)="handleDateClick($event)"
      (eventClick)="handleEventClick($event)"
      (eventDrop)="eventWasDragged($event)"
      (eventResize)="eventWasResized($event)"
      [firstDay]="1"
      [minTime]="'06:00:00'"
      [maxTime]="'20:00:00'"
    ></full-calendar>
  </div>

</section>

</div>
