import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { APIServiceService } from '../api/apiservice.service';
import { RSFirebaseService } from '../services/rsfirebase.service';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';

@Injectable({
  providedIn: 'root'
})
export class CustomerAuthService implements CanActivate {

  constructor(private apiService: APIServiceService, public auth: AngularFireAuth) { }

  async canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): Promise<boolean>{

     const user = await this.getAuthStateChanged();

     if(!user){
       return false;
     }





     const customerExists = await this.apiService.me.isEmailtaken(user.email);
     console.log('Custoemr exists: ', customerExists)
    if(!customerExists.success || customerExists.data.isAvailable){
      try {
        const loggedOut = await this.auth.signOut();
        return false;
      } catch(err){
        return false;

      }

     }
    return true;

  }

  getAuthStateChanged(): Promise<any>{
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user)=>{
        console.log('Got auth state update', user)
        resolve(user);
      })
    })

  }
}
