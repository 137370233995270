import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Customer } from 'src/app/models/customer.model';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { AddressPickerComponent } from 'src/app/components/address-picker/address-picker.component';

@Component({
  selector: 'ubc-customer-details-page',
  templateUrl: './customer-details-page.component.html',
  styleUrls: ['./customer-details-page.component.scss']
})
export class CustomerDetailsPageComponent implements OnInit, AfterViewInit {
  customer: Customer;
  customerDetailsForm: FormGroup;
  horsesDetailsForm: FormGroup;
  tables = [];
  horsesTables = [];
  addressCreatorOpen = false;


  customersHorsesDataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [ 'name', 'breed', 'colour', 'height', 'age', 'sex', 'notes', 'view'];

  @ViewChild('addressPickerComponent', {}) addressPickerComponent: AddressPickerComponent; // the #addressPicker in the template

  constructor(private route: ActivatedRoute, private apiService: APIServiceService, private router: Router) {}

  ngOnInit() {
  }

  ngAfterViewInit(){
    if (!this.route.snapshot.params.id){
      return;
    }
    this.getCustomer();
  }

  getCustomer(){
    this.apiService.customer.getByID(this.route.snapshot.params.id, true)
    .then((response) => {
      console.log(response);
      if (response.success) {
        this.customer = new Customer().deserialize(response.data);
        this.customersHorsesDataSource = new MatTableDataSource(this.customer.horses);
        this.setupCustomerDetailsForm();
      }
    })
    .catch((err) => { console.log(err) });
  }

  setupCustomerDetailsForm(){
    this.customerDetailsForm = new FormGroup({
      firstName : new FormControl(this.customer.firstName),
      lastName: new FormControl(this.customer.lastName),
      email: new FormControl(this.customer.email),
      tel: new FormControl(this.customer.tel),
      billingAddress1: new FormControl(this.customer.billingAddress1),
      billingAddress2: new FormControl(this.customer.billingAddress2),
      billingTown: new FormControl(this.customer.billingTown),
      billingCity: new FormControl(this.customer.billingCity),
      billingPostcode: new FormControl(this.customer.billingPostcode),
      notes: new FormControl(this.customer.notes)

    });
    this.prepareCustomerFormForDisplay();

  }

  prepareCustomerFormForDisplay() {
    if (!this.customer) {
      return;
    }


    this.tables = [];



    const customerDetails = {
      title: 'Customer Details',
      editable: false,
      editClickAction: 'customerEditClicked',
      rows: [
        {
          key : 'Email',
          value: this.customer.email,
          field: null,
          formControl: this.customerDetailsForm.controls.email
        },
        {
          key : 'First Name',
          value: this.customer.firstName,
          field: 'text',
          formControl: this.customerDetailsForm.controls.firstName
        },{
          key : 'Last Name',
          value: this.customer.lastName,
          field: 'text',
          formControl: this.customerDetailsForm.controls.lastName
        },{
          key : 'Tel',
          value: this.customer.tel,
          field: 'text',
          formControl: this.customerDetailsForm.controls.tel
        },{
          key : 'Billing Address',
          value: this.customer.billingAddress1,
          field: 'text',
          formControl: this.customerDetailsForm.controls.billingAddress1
        },{
          key : 'Billing Address 2',
          value: this.customer.billingAddress2,
          field: 'text',
          formControl: this.customerDetailsForm.controls.billingAddress2
        },{
          key : 'Billing Town',
          value: this.customer.billingTown,
          field: 'text',
          formControl: this.customerDetailsForm.controls.billingTown
        },{
          key : 'Billing City',
          value: this.customer.billingCity,
          field: 'text',
          formControl: this.customerDetailsForm.controls.billingCity
        },{
          key : 'Billing Postcode',
          value: this.customer.billingPostcode,
          field: 'text',
          formControl: this.customerDetailsForm.controls.billingPostcode
        },
        {
          key : 'Notes',
          value: this.customer.notes,
          field: 'textarea',
          formControl: this.customerDetailsForm.controls.notes
        }
      ]
    }
    this.tables.push(customerDetails);

  }


  newHorseCreated(horse){
    console.log('New horse created: ', horse);
  }

  newHorseCancelled(){
    console.log('New horse cancelled: ');
  }




  viewHorseClicked(horseID) {
    this.router.navigateByUrl('/horse/' + horseID);
  }


  editClicked(action){

  }

  customerDetailsSubmitted(){
    console.log(this.customerDetailsForm);
    const detailsToUpdate = {};
    Object.keys(this.customerDetailsForm.controls).forEach(key => {
      if (this.customerDetailsForm.controls[key].touched) {
        detailsToUpdate[key] = this.customerDetailsForm.controls[key].value;
      }
    });

    if (Object.keys(detailsToUpdate).length === 0) {
      Swal.fire({ icon: 'error', title : 'There are no changes to save', timer: 1000 });
      return;
    }


    this.apiService.customer.patch(this.customer.id, detailsToUpdate)
    .then((response) => {
      if (response.success) {
        Swal.fire({ icon: 'success', title : 'Customer details saved' });

      } else {
        Swal.fire({ icon: 'error', title : 'Error updating customer' });
      }
    })
    .catch((err) => { console.log(err)});
  }

  addressPicked(address){
      console.log('Address picked', address);
      if (!address.id) {
        this.createAddressClicked();
        return;
      }
  }

  createAddressClicked() {
    this.addressCreatorOpen = true;
  }

  addressCreated(address){
    this.addressCreatorOpen = false;
    this.addressPickerComponent.getAddresesForCustomer(this.customer);
  }

  addressCreateCancelled(){
    this.addressCreatorOpen = false;
  }




}
