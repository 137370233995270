<div class="appointment-summary" *ngIf="appointment">
  <mat-card>
    <mat-card-header>
      <mat-card-subtitle>Appointment Details</mat-card-subtitle>
      <!-- <div mat-card-avatar class="example-header-image"></div> -->
      <mat-card-title>{{appointment.customer.getFullName()}}</mat-card-title>
      <mat-card-subtitle>{{appointment.getTimesSummary()}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

    </mat-card-content>
    <!-- <mat-card-actions>
      <button mat-button>View</button>
    </mat-card-actions> -->
  </mat-card>
</div>
