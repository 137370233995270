import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Customer } from 'src/app/models/customer.model';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { APIResponse } from 'src/app/models/api-response.model';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { HorseDiscipline } from '../../models/horse-disciplines.enum';
import { Horse } from 'src/app/models/horse.model';
import { Address } from 'src/app/models/address.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'ubc-customers-page',
  templateUrl: './customers-page.component.html',
  styleUrls: ['./customers-page.component.scss']
})
export class CustomersPageComponent implements OnInit, OnChanges, AfterViewInit {

  constructor(private apiService: APIServiceService, private router: Router) { }

  faSearch = faSearch;
  // Create customer form
  createCustomerForm: FormGroup;
  // customersListPage: Customer[];
  customersListDataSource = new MatTableDataSource([]);
  customersCurrentPage = 1;
  customersTotalResults = 0;
  selectedCustomer: Customer = null;
  selectedCustomerHorses: Horse[];
  // Customer search
  searchQuery: string;

  // Create horse form
  createHorseForm: FormGroup;

  // Create address form
  createAddressForm: FormGroup;

  // Send SMS
  smsNumber;
  smsMessage;
  showSMSPanel = false;
  smsSendInProgress = false;

  mode = '';

  isLoadingCustomers = false;
  private customerSearchInput: ElementRef;

 @ViewChild('customerSearchInput', {}) set element(customerSearchInput: ElementRef) {
    this.customerSearchInput = customerSearchInput;
    if (!this.customerSearchInput) {
      return;
    }
    fromEvent(this.customerSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      ,filter(res => res.length > 1)
      ,debounceTime(500)
      ,distinctUntilChanged()
    ).subscribe((text: string) => {
      this.searchCustomers(text, 0);
    });
 }

 displayedColumns: string[] = [ 'name', 'email', 'tel', 'view'];

 @ViewChild(MatPaginator, {}) paginator: MatPaginator;

 ngAfterViewInit() {
  this.getCustomers();

}

 ngOnChanges(changes) {
  console.log(changes);
}


  ngOnInit() {
    this.configCreateCustomerForm();
    this.configCreateHorseForm();
    this.configAddressForm();

  }


  // Create customer
  configCreateCustomerForm() {
    this.createCustomerForm = new FormGroup({
      firstName : new FormControl(null, [Validators.required]),
      lastName : new FormControl(null, [Validators.required]),
      email : new FormControl(null, [Validators.required]),
      telephone : new FormControl(null, [Validators.required]),
      billingAddress1 : new FormControl(null, [Validators.required]),
      billingAddress2 : new FormControl(null, [Validators.required]),
      billingTown : new FormControl(null, [Validators.required]),
      billingCity : new FormControl(null, [Validators.required]),
      billingPostcode : new FormControl(null, [Validators.required]),
      notes : new FormControl(null)
    });
  }

  createCustomerSubmit() {
    const customer = new Customer();
    customer.firstName = this.createCustomerForm.value.firstName;
    customer.lastName = this.createCustomerForm.value.lastName;
    customer.email = this.createCustomerForm.value.email;
    customer.tel = this.createCustomerForm.value.telephone;
    customer.billingAddress1 = this.createCustomerForm.value.billingAddress1;
    customer.billingAddress2 = this.createCustomerForm.value.billingAddress2;
    customer.billingTown = this.createCustomerForm.value.billingTown;
    customer.billingCity = this.createCustomerForm.value.billingCity;
    customer.billingPostcode = this.createCustomerForm.value.billingPostcode;
    customer.notes = this.createCustomerForm.value.notes;

    this.apiService.customer.create(customer)
    .then((response: APIResponse) => {
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });


  }

  // End create customer

  startEditCustomer(customer: Customer) {
    this.mode = 'editCustomer';
    this.selectedCustomer = customer;
    this.apiService.customer.getHorses(this.selectedCustomer.id)
    .then((response) => {
      this.selectedCustomerHorses = response.data.results;
    })
    .catch((err) => { console.log(err) });
  }

  // List customers
  getCustomers(page: number = 0) {
    this.isLoadingCustomers = true;
    this.apiService.customer.list(page, 25)
    .then((response) => {
      this.isLoadingCustomers = false;
      if (response.success) {
        this.customersTotalResults = response.data.total;
        const customers = response.data.results.map((customerObj) => new Customer().deserialize(customerObj) );
        this.customersListDataSource = new MatTableDataSource(customers);
        this.addCustomersPaginator();
      }
    })
    .catch((err) => { console.log(err) });
  }

  searchCustomers(text: string, page?: number) {
    this.customersListDataSource = new MatTableDataSource([]);
    this.isLoadingCustomers = true;
    this.apiService.customer.search(text)
      .subscribe((res) => {
        setTimeout(() => {
          this.isLoadingCustomers = false;
          if(res.success) {
            this.customersListDataSource = new MatTableDataSource(res.data.results);
            this.customersTotalResults = res.data.total;
            this.addCustomersPaginator();
          }
          }, 200); // Delay added as results were returning too quick, felt glitchy

      });
  }

  // customersPageChanged($event) {
  //   this.customersCurrentPage = $event;
  //   console.log('Page changed to: ' + this.customersCurrentPage);
  //   this.getCustomers(this.customersCurrentPage);
  // }

  // customerSearchtermUpdated($event) {
  //   console.log($event.srcElement.value);
  // }

  addCustomersPaginator(){
    this.customersListDataSource.paginator = this.paginator;
    this.customersListDataSource.paginator.length = this.customersTotalResults;
    this.customersListDataSource.paginator.page.subscribe((pageEvent) => {
      if(pageEvent.previousPageIndex === pageEvent.pageIndex) {
        return;
      }
      if (this.customerSearchInput.nativeElement.value === '') {
        this.getCustomers(pageEvent.pageIndex);
      } else {
        this.searchCustomers(this.customerSearchInput.nativeElement.value, pageEvent.pageIndex);
      }
      console.log(this.customerSearchInput);
    })
  }

  // End list customers

  viewCustomerClicked(customerId: number) {
    this.router.navigateByUrl('/customer/' + customerId);
  }

  // Create horse
  configCreateHorseForm() {

    const disciplineControls = {};
    for (const discipline in HorseDiscipline) {
      if (!discipline) { continue; }
      disciplineControls[discipline] = new FormControl(null);
   }

    const controls = {
      name : new FormControl(null),
      height : new FormControl(null),
      age : new FormControl(null),
      breed : new FormControl(null),
      sex : new FormControl(null),
      isAffiliated : new FormControl(null),
      colour : new FormControl(null),
      disciplines : new FormGroup(disciplineControls)

    };
    this.createHorseForm = new FormGroup(controls);


  }

  createHorseSubmited() {
    console.log(this.createHorseForm);
    const fValues = {... this.createHorseForm.value};
    fValues.disciplines = Object.keys(this.createHorseForm.value.disciplines).filter((key) => {
      if( this.createHorseForm.value.disciplines[key] === true) { return key; };
    });
    fValues.age = parseInt(fValues.age, 10);
    console.log('Form values ', fValues);
    const horse = new Horse().deserialize(fValues);
    horse.customerId = this.selectedCustomer.id;
    console.log('Form horse ', horse);

    this.apiService.horses.create(horse)
    .then((response) => {
      console.log('Horse created: ' , response);
    })
    .catch((err) => { console.log(err) });
  }

  deleteHorse(horse: Horse) {
    this.apiService.horses.delete(horse.id)
    .then((response) => {
    console.log('Horse deleted: ' , response);
    })
    .catch((err) => { console.log(err) });
  }

  dummyEditHorse(horse: Horse) {
    horse.name = 'UPDATED: ' + horse.name;
    horse.age = 99;
    horse.breed = 'UDPATE: ' + horse.breed;
    this.apiService.horses.update(horse)
    .then((response) => {
      console.log('Horse updated: ' , response.data);
    })
    .catch((err) => { console.log(err) });
  }

  getDisciplines(): any{
    const out = [];
    for (const discipline in HorseDiscipline) {
      if (!discipline) { continue; }
      out.push({ name : discipline, title :  HorseDiscipline[discipline]});
   }
   return out;
  }
  getDisciplineName(key): string {
    return HorseDiscipline[key];
  }

  // End create horse


  // Create address
  configAddressForm() {
    const controls = {
      line1 : new FormControl(null),
      line2 : new FormControl(null),
      town : new FormControl(null),
      city : new FormControl(null),
      postcode : new FormControl(null),
      country : new FormControl(null),
      notes : new FormControl(null)
    }
    this.createAddressForm = new FormGroup(controls);

  }

  submitAddressClicked() {
    console.log(this.createAddressForm);
    this.createAddressForm.value.customerId = 2;
    this.apiService.addresses.create(new Address().deserialize(this.createAddressForm.value))
    .then((response) => {
    console.log('Created address', response.data);
    })
    .catch((err) => { console.log(err) });
  }

  // End create address

  getHorsesForCustomer(customer: Customer) {
    this.apiService.customer.getHorses(customer.id)
    .then((response) => {
      console.log('Customer horses: ', response.data.results);
    })
    .catch((err) => { console.log(err) });
  }

  customerRowClicked(customer: Customer) {
    console.log('Selected customer: ', customer);
    this.apiService.customer.getByID(customer.id)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => { console.log(err) });
  }

  deleteCustomer(customer: Customer) {
    this.apiService.customer.delete(customer.id)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => { console.log(err) });
  }

  updateCustomer(customer: Customer) {
    customer.firstName = 'Updated: ' + customer.firstName;
    customer.lastName = 'Updated: ' + customer.lastName;
    delete(customer.billingAddress1);
    delete(customer.billingAddress2);
    delete(customer.billingCity);
    this.apiService.customer.update(customer)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => { console.log(err)});
  }

  sendSMS(){
    console.log('Sending SMS')

    if(!this.smsNumber || this.smsNumber.length < 10 ){
      alert("Invalid number")
      return;
    }

    if( !this.smsMessage ||  this.smsMessage.length < 5){
      alert("Invalid message")
      return;
    }

    if(this.smsNumber.charAt(0) === '0'){
      this.smsNumber = this.smsNumber.replace('0', '+44')

    }
    this.smsSendInProgress = true;

    this.apiService.utility.sendSMS(this.smsNumber, this.smsMessage)
    .then((response) => {
      // console.log('Message response', response);
      this.smsSendInProgress = false;
      if(response.success && response.data.sid){
        Swal.fire({ icon: 'success', title : 'Message sent!' });
        this.cancelSMS();
      } else {
        Swal.fire({ icon: 'error', title : 'There was a problem sending this message, it has not been sent', timer: 4000 });
      }
    })
    .catch((err) => { console.log(err); this.smsSendInProgress = false; });


  }

  cancelSMS(){
    this.smsNumber = null;
    this.smsMessage = null;
    this.showSMSPanel = false;
    this.smsSendInProgress = false;


  }

  // APOINTMENTS
  createDummyAppointment() {
    // this.apiService.appointments.create({})
    // .then((response) => {
    //   console.log('Appointment created:' , response);
    // })
    // .catch((err) => { console.log(err) });
  }

  deleteDummyAppointment() {
    // this.apiService.appointments.delete('1')
    // .then((response) => {
    //   console.log('Appointment deleted:' , response);
    // })
    // .catch((err) => { console.log(err) });
  }

}
