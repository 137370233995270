<table *ngIf="customer && customer.horses.length > 0" id="horses-table" mat-table [dataSource]="customersHorsesDataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name </th>
    <td mat-cell *matCellDef="let horse"> {{horse.name}} </td>
  </ng-container>

  <ng-container matColumnDef="breed">
    <th mat-header-cell *matHeaderCellDef>Breed </th>
    <td mat-cell *matCellDef="let horse"> {{horse.breed}} </td>
  </ng-container>

  <ng-container matColumnDef="colour">
    <th mat-header-cell *matHeaderCellDef>Colour </th>
    <td mat-cell *matCellDef="let horse"> {{horse.colour}} </td>
  </ng-container>

  <ng-container matColumnDef="height">
    <th mat-header-cell *matHeaderCellDef>Height </th>
    <td mat-cell *matCellDef="let horse"> {{horse.height}} </td>
  </ng-container>

  <ng-container matColumnDef="age">
    <th mat-header-cell *matHeaderCellDef>Age </th>
    <td mat-cell *matCellDef="let horse"> {{horse.age}} </td>
  </ng-container>

  <ng-container matColumnDef="sex">
    <th mat-header-cell *matHeaderCellDef>Sex </th>
    <td mat-cell *matCellDef="let horse"> {{horse.sex}} </td>
  </ng-container>

  <ng-container matColumnDef="notes">
    <th mat-header-cell *matHeaderCellDef>Notes </th>
    <td mat-cell *matCellDef="let horse"> {{horse.notes}} </td>
  </ng-container>



  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let horse"> <button  mat-button color="primary" (click)="horseButtonWasClicked(horse.id)">{{buttonText}}</button> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr class="clickable-row" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="horseRowWasClicked(row.id)" ></tr>

</table>
