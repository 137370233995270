import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { environment }  from '../../../environments/environment';
import { VerifyCodeComponent } from 'src/app/components/verify-code/verify-code.component';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ViewportScroller } from '@angular/common';
import Swal from 'sweetalert2';
import { CardPaymentComponent } from 'src/app/components/card-payment/card-payment.component';
import { PaymentIntentType } from 'src/app/api/payments-endpoint';
import { IProvisionalBookingDetails } from 'src/app/components/provisional-booking/provisional-booking.component';
import { Customer } from 'src/app/models/customer.model';
import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';

export interface IApptmntDetailsFormFieldOption {
  label: string;
  value: string;
}

export interface IApptmntFieldGroup{
  key: string;
  fields: IApptmntDetailsFormField[]
}

export interface IApptmntDetailsFormField {
  label: string;
  type: "text" | "textarea" | "select" | "checkbox" | "boolean" | "radio" | "hidden" | "group" | "number";
  key: string;
  id: number;
  placeholder: string;
  options?: IApptmntDetailsFormFieldOption[],
  required?: boolean;
  fields?: IApptmntFieldGroup
}

export interface IApptmntDetailsFormFieldsSection{
  id: number;
  key: string;
  title: string;
  fields: IApptmntDetailsFormField[];
  enabled: boolean;
  buttonAction?: any;
}
export interface IApptmntDetailsFormFields{
  sections: IApptmntDetailsFormFieldsSection[]
}

@Component({
  selector: 'ubc-new-booking-info',
  templateUrl: './new-booking-info.component.html',
  styleUrls: ['./new-booking-info.component.scss']
})
export class NewBookingInfoComponent implements OnInit {

  @ViewChild('smsConfirmWrap',{}) smsConfirmWrap: VerifyCodeComponent;
  @ViewChild('cardPaymentElement') cardPaymentElement: CardPaymentComponent;

  constructor(
    private route: ActivatedRoute,
    private apiService: APIServiceService,
    private cookieService: CookieService,
    private viewportScroller: ViewportScroller,
    private renderer: Renderer2,
    public auth: AngularFireAuth
    ) { }

    bookingId: string;
    requestedCode = false;
    resendAvailable = false;
    resendInterval = null;
    resendIn = 10;
    codeAttemptInvalid = false;
    requestedNumberLast4 = '';
    codeConfirmed = false;

    checkingForAuth = true;

    recalledResponses: any;

    form: FormGroup;

    termsOpen = false;

    bookingDetails: IProvisionalBookingDetails;




    formFields: IApptmntDetailsFormFields = {
      sections: [
        {
          enabled: true,
          id: 1,
          key: "rider",

          title: "Please complete the details below to confirm your RS Saddles Appointment",
          fields: [
            {
              id: 1,
              label: "Rider / Owner name",
              key: "riderOwnerName",
              placeholder: "Name of rider / owner",
              type: 'text',
              required: true
            },
            {
              id: 2,
              label: "Home address",
              key: "riderAddress",
              type: 'group',
              placeholder: 'Your address',
              required: true,
              fields: {
                key: 'riderAddress',
                fields : [
                {
                  id: 1,
                  label: "House name / number",
                  key: "riderAddressNumber",
                  type: 'text',
                  placeholder: 'Your house name / number',
                  required: true
                },
                {
                  id: 2,
                  label: "Street name",
                  key: "riderAddressStreet",
                  type: 'text',
                  placeholder: 'Your street name',
                  required: true
                },
                {
                  id: 3,
                  label: "Town",
                  key: "riderAddressTown",
                  type: 'text',
                  placeholder: 'Your town',
                  required: true
                },
                {
                  id: 4,
                  label: "Postcode",
                  key: "riderAddressPostcode",
                  type: 'text',
                  placeholder: 'Your town',
                  required: true
                },
                {
                  id: 5,
                  label: "Address ID",
                  key: "riderAddressId",
                  type: 'hidden',
                  placeholder: '',
                  required: false
                }
              ]
            }
            },

            {
              id: 3,
              label: "Rider's height",
              key: "riderHeight",
              type: "text",
              placeholder: "Please provide the height of the rider",
              required: true
            },
            {
              id: 4,
              label: "Rider's weight",
              key: "riderWeight",
              type: "text",
              placeholder: "Please provide the weight of the rider",
              required: true
            },
            {
              id: 5,
              label: "A brief description of any rider injuries, back problems or medical issues?",
              key: "riderInjuries",
              type: "textarea",
              placeholder: "Please let us know of any relevant injuries of medical issues",
              required: true
            },
            {
              id: 6,
              label: "Yard/Appointment Address",
              key: "yardAddress",
              type: "group",
              placeholder: "The address we should attend",
              required: true,
              fields: {
                key: 'yardAddress',
                fields : [
                {
                  id: 1,
                  label: "House name / number",
                  key: "yardAddressNumber",
                  type: 'text',
                  placeholder: 'Your house name / number',
                  required: true
                },
                {
                  id: 2,
                  label: "Street name",
                  key: "yardAddressStreet",
                  type: 'text',
                  placeholder: 'Your street name',
                  required: true
                },
                {
                  id: 3,
                  label: "Town",
                  key: "yardAddressTown",
                  type: 'text',
                  placeholder: 'Your town',
                  required: true
                },
                {
                  id: 4,
                  label: "Postcode",
                  key: "yardAddressPostcode",
                  type: 'text',
                  placeholder: 'Your town',
                  required: true
                },
                {
                  id: 4,
                  label: "Notes",
                  key: "yardAddressNotes",
                  type: 'textarea',
                  placeholder: 'Please incude any access details / gate codes etc',
                  required: false
                },
                {
                  id: 5,
                  label: "Address Id",
                  key: "yardAddressId",
                  type: 'hidden',
                  placeholder: '',
                  required: false
                }
              ]
            }
            },
            {
              id: 7,
              label: "Do you have an Arena or a safe area to ride?",
              key: "safeAreaConfirmed",
              type: "radio",
              placeholder: "Please indicate whether you have a safe area to ride for the appointment",
              options: [
                {label: 'Yes', value: 'Yes'},
                {label: 'No', value: 'No'}
              ],
              required: true
            },
            {
              id: 8,
              label: "We require you to have safe riding equipment for the appointment, ie boots, helmet. Can you assure this please?",
              key: "ridingEquipmentConfirmed",
              type: "radio",
              placeholder: "Please indicate whether you have suitable riding equipment for the appointment ",
              options: [
                {label: 'Yes', value: 'Yes'},
                {label: 'No', value: 'No'}
              ],
              required: true
            }
          ]
        },
        {
          enabled: true,
          title: "Horse Details",
          id: 2,
          key: "horse1",
          fields: this.getHorseFields(10),
          buttonAction: {title: "ADD ANOTHER HORSE", methodName: "toggleHorse", parameter: 2}
        },
        {
          enabled: false,
          title: "Horse 2 Details",
          id: 3,
          key: "horse2",
          fields: this.getHorseFields((this.getHorseFields(9).length * 1) + 9),
          buttonAction: {title: "ADD ANOTHER HORSE", methodName: "toggleHorse", parameter: 3}
        },
        {
          enabled: false,
          title: "Horse 3 Details",
          id: 4,
          key: "horse3",
          fields: this.getHorseFields((this.getHorseFields(9).length * 2) + 9),
          buttonAction: {title: "ADD ANOTHER HORSE", methodName: "toggleHorse", parameter: 4}
        },
        {
          enabled: false,
          title: "Horse 4 Details",
          id: 5,
          key: "horse4",
          fields: this.getHorseFields((this.getHorseFields(9).length * 3) + 9),
          buttonAction: {title: "ADD ANOTHER HORSE", methodName: "toggleHorse", parameter: 5}
        },
        {
          enabled: false,
          title: "Horse 5 Details",
          id: 6,
          key: "horse5",
          fields: this.getHorseFields((this.getHorseFields(9).length * 4) + 9)
        },
        {
          enabled: true,
          title: "Our Booking Terms & Conditions",
          id: 7,
          key: 'termsConditions',
          fields:[
            {
              id: 99,
              label: "Please read and accept our booking terms and conditions?",
              key: "termsAccepted",
              type: "checkbox",
              placeholder: "",
              required: true
            }
          ]
        }

      ]
    }



    login(){
      // this.auth.signInWithEmailAndPassword(new firebase.auth.GoogleAuthProvider);
    }


  ngOnInit(): void {
    this.bookingId = this.route.snapshot.params.id;
    if(!this.bookingId){
      return;
    }


    this.initBooking();






  }

  async initBooking(){
    const existingResponses = await this.apiService.me.getResponsesForProvisionalAppointment(this.bookingId);
    console.log('Returned responses ', existingResponses);

    if(existingResponses.success && existingResponses.data){
      this.recalledResponses = existingResponses.data;
    }

    const bookingResponse = await this.apiService.me.getProvisionalAppointment(this.bookingId);

    if(!bookingResponse.success){
      return;
    }

    const customer = new Customer().deserialize(bookingResponse.data.customer);
    this.bookingDetails = {
      name : customer.getFullName(),
      email : customer.email,
      startTime : moment(bookingResponse.data.startTime),
      endTime: moment(bookingResponse.data.endTime),
      price: 55,
      customer,
      depositPaymentId: bookingResponse.data.depositPaymentId
    }

    console.log(this.bookingDetails)

    const horsesResponse = await this.apiService.me.getHorses();
    this.bookingDetails.customer.horses = (horsesResponse.success) ? horsesResponse.data.results : []

    const addresses = await this.apiService.me.getAddresses();
    this.bookingDetails.customer.addresses = (addresses.success) ? addresses.data.results : []

    this.initForm();
  }

  // async initBookingOLD(){


  //   const token = this.cookieService.get(environment.CUSTOMER_APPOINTMENT_TOKEN_COOKIE_KEY);

  //   if(token){
  //     this.apiService.appointments.getResponsesForProvisionalAppointment(this.bookingId)
  //     .then((response) => {
  //       this.checkingForAuth = false;
  //         this.codeConfirmed = true;
  //         if(response.data){
  //           this.recalledResponses = response.data;
  //         }
  //         return this.apiService.appointments.getProvisional(this.bookingId);


  //     })
  //     .then((bookingResponse)=>{
  //       if(!bookingResponse.success) {
  //         return;
  //       }
  //       const customer = new Customer().deserialize(bookingResponse.data.customer);
  //       this.bookingDetails = {
  //         name : customer.getFullName(),
  //         email : customer.email,
  //         startTime : moment(bookingResponse.data.startTime),
  //         endTime: moment(bookingResponse.data.endTime),
  //         price: 55,
  //         customer
  //       }
  //       return this.apiService.customer.getProvisionalHorses(customer.id, this.bookingId)

  //     })
  //     .then((horsesResponse)=>{
  //       console.log(horsesResponse);
  //       this.bookingDetails.customer.horses = (horsesResponse.success) ? horsesResponse.data.results : [];
  //       return this.apiService.customer.getAddresses(this.bookingDetails.customer.id)

  //     })
  //     .then((addresses)=>{
  //       console.log('Customer addresses: ', addresses);
  //       this.initForm();
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       this.checkingForAuth = false;
  //      });
  //   } else {
  //     this.checkingForAuth = false;
  //   }
  // }

  initForm(){
    try{
    const formOptions = {};
    this.formFields.sections.forEach(section => {
      const fieldsControls = {};
      let sectionIsEmpty = true;
      section.fields.forEach(field => {
        if(field.type === 'group'){
          // This field has a series of sub fields
          const groupFieldControls = {};
          field.fields.fields.forEach(subField => {
            // console.log('Checking ', this.recalledResponses[section.key][field.key][subField.key])
            const recalledVal2 = (this.recalledResponses && this.recalledResponses[section.key] && this.recalledResponses[section.key][field.key] && this.recalledResponses[section.key][field.key][subField.key]) ? this.recalledResponses[section.key][field.key][subField.key] : "";
            const validators2 = [];
            if(subField.required){
              validators2.push(Validators.required);

              if(subField.type === 'checkbox'){
                validators2.push(Validators.requiredTrue);
              }
            }
            groupFieldControls[subField.key] = new FormControl(recalledVal2, validators2);
          })
          fieldsControls[field.key] = new FormGroup(groupFieldControls);

        } else {

        const validators = [];
        if(field.required){
          validators.push(Validators.required);

          if(field.type === 'checkbox'){
            validators.push(Validators.requiredTrue);
          }
        }
        const recalledVal = (this.recalledResponses && this.recalledResponses[section.key] && this.recalledResponses[section.key][field.key]) ? this.recalledResponses[section.key] && this.recalledResponses[section.key][field.key] : '';
        if(recalledVal != '') {
          sectionIsEmpty = false;
        }
        fieldsControls[field.key] = new FormControl(recalledVal,validators)

        }
      });
      formOptions[section.key] = new FormGroup(fieldsControls);

      if(!sectionIsEmpty){
        section.enabled = true;
      }

    });


    this.form = new FormGroup(formOptions);
    setTimeout(()=>{
      if(this.cardPaymentElement && this.form.valid){
        console.log('Form inited', this.cardPaymentElement)
        this.cardPaymentElement.requestPaymentIntent(PaymentIntentType.BOOKING, this.bookingId);
      }
    }, 1000);

  } catch(err){
    console.log('ERR', err);

  }


  }

  selectHorseForSection(section, horse){
    if(section.controls.horseId.value === horse.id){
      section.controls.horseId.setValue(null);
      section.controls.horseName.setValue(null);
      section.controls.horseAge.setValue(null);
      section.controls.horseHeight.setValue(null);
      section.controls.horseGender.setValue(null);
    } else {
      section.controls.horseId.setValue(horse.id);
      section.controls.horseName.setValue(horse.name);
      section.controls.horseAge.setValue(horse.age);
      section.controls.horseHeight.setValue(horse.height);
      section.controls.horseGender.setValue(horse.sex);
    }



  }

  selectRiderAddress(address){

    if(address.id === this.form.get('rider.riderAddress.riderAddressId').value){
      this.form.get('rider.riderAddress.riderAddressNumber').setValue(null)
      this.form.get('rider.riderAddress.riderAddressStreet').setValue(null)
      this.form.get('rider.riderAddress.riderAddressTown').setValue(null)
      this.form.get('rider.riderAddress.riderAddressPostcode').setValue(null)
      this.form.get('rider.riderAddress.riderAddressId').setValue(null)
      return;
    }
    this.form.get('rider.riderAddress.riderAddressNumber').setValue(address.line1)
    this.form.get('rider.riderAddress.riderAddressStreet').setValue(address.line2)
    this.form.get('rider.riderAddress.riderAddressTown').setValue(address.town)
    this.form.get('rider.riderAddress.riderAddressPostcode').setValue(address.postcode)
    this.form.get('rider.riderAddress.riderAddressId').setValue(address.id)
  }

  selectYardAddress(address){
    if(address.id === this.form.get('rider.yardAddress.yardAddressId').value){
      this.form.get('rider.yardAddress.yardAddressNumber').setValue(null)
      this.form.get('rider.yardAddress.yardAddressStreet').setValue(null)
      this.form.get('rider.yardAddress.yardAddressTown').setValue(null)
      this.form.get('rider.yardAddress.yardAddressPostcode').setValue(null)
      this.form.get('rider.yardAddress.yardAddressNotes').setValue(null)
      this.form.get('rider.yardAddress.yardAddressId').setValue(null)
      return;
    }
    this.form.get('rider.yardAddress.yardAddressNumber').setValue(address.line1)
    this.form.get('rider.yardAddress.yardAddressStreet').setValue(address.line2)
    this.form.get('rider.yardAddress.yardAddressTown').setValue(address.town)
    this.form.get('rider.yardAddress.yardAddressPostcode').setValue(address.postcode)
    this.form.get('rider.yardAddress.yardAddressNotes').setValue(address.notes)
    this.form.get('rider.yardAddress.yardAddressId').setValue(address.id)
  }

  paymentDidInit(){
    setTimeout(()=>{
      if(this.cardPaymentElement && this.form.valid){
        this.cardPaymentElement.requestPaymentIntent(PaymentIntentType.BOOKING, this.bookingId);
      }
    }, 2000);
  }



  requestAccessCode(){

    this.resendInterval = setInterval(()=>{
      if(this.resendIn > 1){
        this.resendIn--;
        this.resendAvailable = false;
      } else {
        clearInterval(this.resendInterval);
        this.resendAvailable = true;
      }
    },1000);
    const outerThis = this;
    this.apiService.appointments.requestAccessSMSForBookingID(this.bookingId)
    .then(function(requested){
      if(requested.success){
        outerThis.requestedCode = true;
        outerThis.requestedNumberLast4 = requested.data.number;
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  // codeSubmitted(code) {
  //   clearInterval(this.resendInterval);
  //   this.resendAvailable = true;
  //   this.apiService.appointments.confirmAccessSMSForBookingID(this.bookingId, code)
  //   .then((response) => {
  //     console.log('Cofirmation response: ', response);
  //     if(!response.success){
  //       this.codeAttemptInvalid = true;
  //       setTimeout(()=>{
  //         this.codeAttemptInvalid = false;

  //         this.smsConfirmWrap.clearCode();

  //       }, 1000);

  //     } else {
  //       this.cookieService.set(environment.CUSTOMER_APPOINTMENT_TOKEN_COOKIE_KEY, response.data.token, 1);
  //       this.recalledResponses = response.data.responses;
  //       this.codeConfirmed = true;
  //       this.initBooking();
  //     }
  //   })
  //   .catch((err) => { console.log(err) });
  // }



  getHorseFields(startId): IApptmntDetailsFormField[]{
    const fields: IApptmntDetailsFormField[] = [
      {
        id: startId,
        label: "Horse id",
        key: "horseId",
        type: "hidden",
        placeholder: ""
      },
      {
        id: startId,
        label: "Horse name",
        key: "horseName",
        type: "text",
        placeholder: "Please enter the name of the horse"
      },
      {
        id: startId,
        label: "Horse age",
        key: "horseAge",
        type: "text",
        placeholder: "Please enter the age of the horse"
      },
      {
        id: startId,
        label: "Horse height",
        key: "horseHeight",
        type: "text",
        placeholder: "Please enter the height of the horse"
      },
      {
        id: startId,
        label: "Horse gender",
        key: "horseGender",
        type: "radio",
        placeholder: "Please select the name of the horse",
        options: [
          {label: 'Mare', value: 'mare'},
          {label: 'Gelding', value: 'gelding'},
          {label: 'Stallion', value: 'stallion'}
        ]
      },
      {
        id: startId,
        label: "What activities do you do with your horse and at what level?",
        key: "horseActivities",
        type: "textarea",
        placeholder: "Activities and levels of competition for this horse"
      },
      {
        id: startId,
        label: "A brief description of any injuries, back problems or Vet history?",
        key: "horseHistory",
        type: "textarea",
        placeholder: "Injuries, back problems or any ther relevant vet history"
      },
      {
        id: startId,
        label: "Do You require a saddle check or new saddle?",
        key: "horseAppointmentType",
        type: "radio",
        placeholder: "Please select the appointment type for this horse",
        options: [
          {label: 'Saddle Check', value: 'Saddle Check'},
          {label: 'New Saddle', value: 'New Saddle'},
          {label: 'Not sure', value: 'Not sure'}
        ]
      },
      {
        id: startId,
        label: "What is your current Saddle? Manufacturer, Last checked, By which Saddle Fitter, Any Issues?",
        key: "horseCurrentSaddle",
        type: "textarea",
        placeholder: "Current saddle details, previous saddle fitter and any issues"
      },
      {
        id: startId,
        label: "Do you require us to bring any alternative saddles, if so what type?",
        key: "horseAlternativeSaddles",
        type: "radio",
        placeholder: "Please select required alternatve saddle types for this horse",
        options: [
          {label: 'No', value: 'No'},
          {label: 'Jump', value: 'Jump'},
          {label: 'GP', value: 'GP'},
          {label: 'Dressage', value: 'Dressage'},
          {label: 'Other', value: 'Other'}
        ]
      },
      {
        id: startId,
        label: "If you do require a new saddle, what is your budget?",
        key: "horseSaddleBudget",
        type: "number",
        placeholder: "Which budget would you like the a new saddle to be within"
      }


    ]
    let i = 0;
    const out = fields.map((field)=>{
      field.id += i;
      i++;
      return field;
    })
    return out;

  }

  sectionActionFunction(methodName, parameter){
    this[methodName](parameter);
  }

  toggleHorse(horseIndex){
    this.formFields.sections[horseIndex].enabled = !this.formFields.sections[horseIndex].enabled
    const currentPosition = this.viewportScroller.getScrollPosition();
    setTimeout(()=>{
      this.viewportScroller.scrollToPosition([0, currentPosition[1] + 450]);
    },0);

  }

  invalidSubmitClicked(){
    console.log('row clicked');
  }

  async beforePaymentSubmit(callback){
    const saved = await this.submitForm();
    if(callback){
      callback(saved);
    }
  }

  paymentSuccess(){
    // this.submitForm();
    console.log('Payment success, client side')
  }

  async submitForm(): Promise<boolean>{


    const response = await this.apiService.me.updateResponsesForBooking(this.bookingId, JSON.stringify(this.form.value))
    if(response.success){

      Swal.fire({
        icon: 'success',
        title : 'Appointment details saved'
         })
      .then((dismissed) => {

      })
      .catch((err) => { console.log(err) });
      return true;
    }

    Swal.fire({
      icon: 'warning',
      title : 'There was a problem while saving your details, please try again'
       })
    .then((dismissed) => {

    })
    .catch((err) => { console.log(err) });
    return false;


    // .then((response) => {
    //   console.log('saved response' , response);
    //   if(response.success){

    //     Swal.fire({
    //       icon: 'success',
    //       title : 'Appointment details saved'
    //        })
    //     .then((dismissed) => {

    //     })
    //     .catch((err) => { console.log(err) });
    //     return true;
    //   } else {
    //     Swal.fire({
    //       icon: 'warning',
    //       title : 'There was a problem while saving your details, please try again'
    //        })
    //     .then((dismissed) => {

    //     })
    //     .catch((err) => { console.log(err) });
    //     return false;
    //   }
    // })
    // .catch((err) => { console.log(err) });
  }

  saveClicked(){
    this.submitForm();
  }

  openTerms(){
    this.renderer.addClass(document.body, 'modal-open');
    this.termsOpen = true;
    console.log(this.form.value);
  }
  closeTerms(){
    this.renderer.removeClass(document.body, 'modal-open');
    this.termsOpen = false;
  }

  updateDetailsClicked(){
    this.form.controls.termsConditions.setValue({termsAccepted: false})
  }







}
