<h2>Users Management</h2>

<h4>Create new user</h4>

<form [formGroup]="createUserForm" (ngSubmit)="createUserSubmit()">
  <label>First Name: </label>
  <input type="text" formControlName="firstName"/>
  <br>
  <label>Last Name: </label>
  <input type="text" formControlName="lastName"/>
  <br>
  <label>Email Name: </label>
  <input type="email" formControlName="email" autocomplete="new-password"/>
  <br>
  <button type="submit" [disabled]="createUserForm.invalid">Create User</button>
</form>

STATUS: {{createUserForm.status}}
