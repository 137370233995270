import { IDeserializable } from "./deserializable.model";
import { HorseDiscipline } from './horse-disciplines.enum';

export class Horse implements IDeserializable {

    public id: number;
    public customerId: number;
    public name: string;
    public height: string;
    public age: number;
    public breed: string;
    public sex: string;
    public disciplines: HorseDiscipline[];
    public isAffiliated: boolean;
    public colour: string;
    public notes: string;

    public deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }


}
