import { Injectable } from '@angular/core';
import { Address } from '../models/address.model';

export class GoogleAddressResultItem {
  // tslint:disable-next-line: variable-name
  long_name: string;
  // tslint:disable-next-line: variable-name
  short_name: string;
  types: string[];
}
export class GoogleLatLngObject {
  lat: number;
  lng: number;
}
export class GoogleAddressResult {
  // tslint:disable-next-line: variable-name
  address_components: GoogleAddressResultItem[];
  // tslint:disable-next-line: variable-name
  formatted_address: string;
  geometry: {
    location: GoogleLatLngObject,
    location_type: string,
    viewport: {
      northeast: GoogleLatLngObject,
      southwest: GoogleLatLngObject}
    };
    // tslint:disable-next-line: variable-name
    place_id: string;
    // tslint:disable-next-line: variable-name
    plus_code: {compound_code: string, global_code: string};
    types: string;

}

@Injectable({
  providedIn: 'root'
})
export class AddressesServiceService {

  constructor() { }

  parseGoogleAddressresult(googleAddressResult: GoogleAddressResult): Address {
    if (!googleAddressResult) {
      return null;
    }
    let streetNumber = '';
    let line1 = '';
    let line2 = '';
    let town = '';
    let city = '';
    let postcode = '';

    for (const component of googleAddressResult.address_components) {
      if (component.types.indexOf('street_number') > -1) {
        streetNumber = component.long_name;
      }
      if (component.types.indexOf('route') > -1) {
        line1 = component.long_name;
      }
      if (component.types.indexOf('locality') > -1) {
        line2 = component.long_name;
      }
      if (component.types.indexOf('postal_town') > -1) {
        town = component.long_name;
      }
      if (component.types.indexOf('"administrative_area_level_2"') > -1 || component.types.indexOf('political') > -1) {
        city = component.long_name;
      }
      if (component.types.indexOf('postal_code') > -1) {
        postcode = component.long_name;
      }
    }

    const address = new Address();
    address.line1 = (streetNumber === '') ? line1 : streetNumber + ' ' + line1;
    address.line2 = line2;
    address.town = town;
    address.city = city;
    address.postcode = postcode;
    address.lat = googleAddressResult.geometry.location.lat;
    address.lng = googleAddressResult.geometry.location.lng;
    return address;
  }

  validatePostcode( postcode ): boolean {
    // const pv = require('postcode-validator');
    // console.log('Validating postcode ' + postcode);
    // const valid = pv.validate(postcode, 'UK');
    // console.log(valid);
    // return valid;
    return true;
  }

}
