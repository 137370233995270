import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppointmentType } from 'src/app/models/appointment-type.model';
import { SaddleType } from 'src/app/models/saddle-type.model';
import { Fitter } from 'src/app/models/fitter.model';
import * as moment from 'moment';
import { Appointment } from 'src/app/models/appointment.model';
import { EntityTypesService } from 'src/app/services/entity-types.service';

@Component({
  selector: 'ubc-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss']
})
export class AppointmentDetailsComponent implements OnInit {
  @Input() appointment: Appointment;
  @Output() detailsUpdated = new EventEmitter();

  appointmentDetailsForm: FormGroup;



  constructor(private entityTypesService: EntityTypesService) { }
  appointmentTypes: AppointmentType[];
  saddleTypes: SaddleType[];
  fitters: Fitter[];
  // appointmentTypes: AppointmentType[] = [
  //   new AppointmentType(1, 'Saddle refit'),
  //   new AppointmentType(2, 'Saddle delivery'),
  //   new AppointmentType(3, 'Bridle fitting'),
  //   new AppointmentType(4, 'New saddle')
  // ];

  // saddleTypes: SaddleType[] = [
  //   new SaddleType(1, 'Dressage Saddle'),
  //   new SaddleType(2, 'General Purpose Saddle'),
  //   new SaddleType(3, 'Jumping saddle')
  // ];

  // fitters: Fitter[] = [
  //   new Fitter(1, 'Ruth'),
  //   new Fitter(2, 'Becky'),
  //   new Fitter(3, 'Both')
  // ];



  ngOnInit() {
    this.entityTypesService.getAppointmentTypes()
    .then((appointmentTypes) => {
      this.appointmentTypes = appointmentTypes;
      return this.entityTypesService.getSaddleTypes()
    })
    .then((saddleTypes) => {
      this.saddleTypes = saddleTypes;
      return this.entityTypesService.getFitters()
    })
    .then((fitters) => {
      this.fitters = fitters;
      this.setupAppointmentDetailsForm();
    })
    .catch((err) => { console.log(err) });


  }

  setupAppointmentDetailsForm(){
    this.appointmentDetailsForm = new FormGroup({
      fitter: new FormControl(),
      start : new FormControl(this.appointment.startTime.format()),
      end : new FormControl(this.appointment.endTime.format()),
      saddleType : new FormControl(null),
      type: new FormControl(),
      newSaddleInterest: new FormControl(false),
      budget: new FormControl(),
      notes: new FormControl(),


    });

    this.appointmentDetailsForm.controls.fitter.setValidators([Validators.required]);
    this.appointmentDetailsForm.controls.saddleType.setValidators([Validators.required]);
    this.appointmentDetailsForm.controls.type.setValidators([Validators.required]);


    this.appointmentDetailsForm.controls.type.valueChanges.subscribe(
      apptType => {
        if (apptType.id !== 12) {
          this.appointmentDetailsForm.controls.saddleType.setValidators(null);
        } else {
          this.appointmentDetailsForm.controls.saddleType.setValidators([Validators.required]);
        }
        this.appointmentDetailsForm.controls.saddleType.updateValueAndValidity();
      }
    )
  }

  appointmentDetailsSubmitted() {
    const vals = this.appointmentDetailsForm.value;
    this.appointment.saddleType = vals.saddleType;
    this.appointment.type = vals.type;
    this.appointment.notes = vals.notes;
    this.appointment.fitter = vals.fitter;
    this.appointment.budget = vals.budget;
    this.appointment.newSaddleInterest = vals.newSaddleInterest;
    this.detailsUpdated.emit(this.appointment);
  }

  refitIsSelected() {
    return (this.appointmentDetailsForm.value.type && this.appointmentDetailsForm.value.type.id === 8);
  }

  newSaddleIsSelected (){
    return (this.appointmentDetailsForm.value.type && this.appointmentDetailsForm.value.type.id === 12);
  }


}
