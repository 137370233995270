import { APIResponse } from '../models/api-response.model';
import { APIServiceService } from './apiservice.service';
import { User } from '../models/user.model';
import { Observable, observable } from 'rxjs';

export class UtilityEndpoint {

  constructor(private apiService: APIServiceService) {}



  sendSMS(smsNumber: string, smsMessage: string): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST('utility/sms', token, {smsNumber, smsMessage}).subscribe(response => resolve(response));
    });
  }

}
