import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';
import { APIServiceService } from 'src/app/api/apiservice.service';
import { Horse } from 'src/app/models/horse.model';
import { MatListOption, MatSelectionListChange, MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'ubc-horse-picker',
  templateUrl: './horse-picker.component.html',
  styleUrls: ['./horse-picker.component.scss']
})
export class HorsePickerComponent implements OnInit, AfterViewInit {

  @Input() customer: Customer;
  @Output() horsePicked = new EventEmitter();

  horses: Horse[] = [];
  constructor(private apiService: APIServiceService) { }

  @ViewChild('horseSelect', {}) horseSelect: MatSelectionList;

  ngAfterViewInit() {
    this.horseSelect.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.horseSelect.deselectAll();

      const option: MatListOption = s.option;

      if (option.value !== 'new_horse') {
        s.option.selected = true;
        this.horsePicked.emit(option.value);
      } else {
        this.horsePicked.emit(new Horse());
      }
    });
  }

  ngOnInit() {


  }




  getHorsesForCustomer(customer: Customer) {

    if (customer ) {
      this.customer = customer;
    } else {
      return;
    }


    this.apiService.customer.getHorses(this.customer.id)
    .then((response) => {
      if (response.success) {
        this.horses = response.data.results;
      }
    })
    .catch((err) => { console.log(err) });
  }

}
