import { APIResponse } from '../models/api-response.model';
import { APIServiceService } from './apiservice.service';
import { Address } from '../models/address.model';
import { Observable, observable } from 'rxjs';

export class AddressEndpoint {
  endpoint = 'addresses/';
  constructor(private apiService: APIServiceService) {}



  create(address: Address): Promise<{success: boolean, data: { results: Address }}> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST(this.endpoint, token, address).subscribe(response => resolve(response));
    });
  }

  geoLookup(term: string): Observable<any> {
    return new Observable((obs) => {
      //const token = this.apiService.getCurrentUserToken();
      // if (!token) {
      //   obs.error('no_auth_token');
      //   return;
      // }
      this.apiService.doGET(this.endpoint + '/lookup', null, { term })
      .subscribe(response => { obs.next(response); obs.complete(); });
    });
  }

  lookupPostcode(postcode: string): Observable<any> {
    return new Observable((obs) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        obs.error('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + '/lookup/postcode', token, { postcode })
      .subscribe(response => { obs.next(response); obs.complete(); });
    });
  }

  searchAddress(term: string): Observable<any> {
    return new Observable((obs) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        obs.error('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + '/search', token, { term })
      .subscribe(response => { obs.next(response); obs.complete(); });
    });
  }





}
