import { APIResponse } from '../models/api-response.model';
import { APIServiceService } from './apiservice.service';
import { Customer } from '../models/customer.model';
import { Observable } from 'rxjs';
import { Horse } from '../models/horse.model';
import { Address } from '../models/address.model';

export class CustomerEndpoint {

  constructor(private apiService: APIServiceService) {}
  endpoint = 'customers/';

  create(customer: Customer): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST(this.endpoint, token, customer).subscribe(response => resolve(response));
    });
  }

  patch(customerID: number, detailsToUpdate: any): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST(this.endpoint + 'patch/' + customerID, token, detailsToUpdate).subscribe(response => resolve(response));
    })
  }

  update(customer: Customer): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doPOST(this.endpoint + customer.id, token, customer).subscribe(response => resolve(response));
    });
  }

  delete(customerId: number): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doDELETE(this.endpoint + customerId, token).subscribe(response => resolve(response));
    });
  }

  deleteAddress(customerId: number, addressId: number): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doDELETE(this.endpoint + customerId + '/address/' + addressId, token).subscribe(response => resolve(response));
    });
  }

  list(page?: number, size?: number): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + 'list', token, { page, size }).subscribe(response => resolve(response));
    });
  }

  search(term: string, page?: number, size?: number): Observable<{success: boolean, data: { total: number, results: Customer[] }}> {
    return new Observable(obs => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        obs.error('no_auth_token');
        return;
      }
      if(typeof page === 'undefined') { page = 0; }
      if(typeof size === 'undefined' || size === 0 || size > 100) { size = 100; }
      this.apiService.doGET(this.endpoint + 'search', token, { term, page, size })
      .subscribe(
        response => {
          if(response.success) {
            response.data.results = response.data.results.map((c) => {
              return new Customer().deserialize(c);
            });
          }
          obs.next(response);
          obs.complete();
        });
    });
  }

  getByID(id: number, includeHorses: boolean = false): Promise<APIResponse> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + id, token, { includeHorses }).subscribe(response => resolve(response));
    });
  }

  getHorses(customerId: number): Promise<{success: boolean, data: { results: Horse[] }}> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + customerId + '/horses', token, null)
      .subscribe((response) => {
        if (response.success) {
          response.data.results = response.data.results.map(result =>   new Horse().deserialize(result));
        }
        resolve(response);
      });
    });
  }

  getProvisionalHorses(customerId: number, bookingId: string): Promise<{success: boolean, data: { results: Horse[] }}> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentApptDetailsToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + customerId + "/" + bookingId + '/provisional/horses', token, {})
      .subscribe((response) => {
        if (response.success) {
          response.data.results = response.data.results.map(result =>   new Horse().deserialize(result));
        }
        resolve(response);
      });
    });
  }

  getAddresses(customerId: number): Promise<{success: boolean, data: { results: Address[] }}> {
    return new Promise((resolve, reject) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        reject('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + customerId + '/addresses', token, null)
      .subscribe((response) => {
        if (response.success) {
          response.data.results = response.data.results.map(result =>   new Address().deserialize(result));
        }
        resolve(response);
      });
    });
  }

  isEmailtaken(email: string): Observable<any> {
    return new Observable((obs) => {
      const token = this.apiService.getCurrentUserToken();
      if (!token) {
        obs.error('no_auth_token');
        return;
      }
      this.apiService.doGET(this.endpoint + '/email', token, { email }).subscribe(response => { obs.next(response); obs.complete();});
    });
  }
}
