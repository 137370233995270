import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class RSFirebaseService {
  public authState: any = null;
  public idToken: string = null;

  constructor(private auth: AngularFireAuth) {

    this.auth.authState.subscribe( authState => {
      console.log('Auth state changed', authState)
      this.authState = authState;
    });

  }

  async getCurrentUser(): Promise<any>{
    try {
      //const user = await firebase.auth();
      //return user;
    } catch(err){
      return null;
    }
  }

  async getIdtoken(): Promise<string>{
    try {
    const token = await firebase.auth().currentUser.getIdToken();
    return token;
    } catch(err){
      console.log('Error getting ID Token for user')
      return null;
    }
  }

}
