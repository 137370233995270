import { Component, OnInit } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import {
  AuthService,
  GoogleLoginProvider
} from 'angularx-social-login';
import { APIServiceService } from './api/apiservice.service';
import { UserAuthService } from './auth/user-auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { LocationStrategy, PlatformLocation } from '@angular/common';

import { environment } from '../environments/environment';
import firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'ubc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'RSSaddlesBookingSystem';
  user;
  loggedIn;
  constructor(
    private socialAuthService: AuthService,
    private apiService: APIServiceService,
    public userAuthService: UserAuthService,
    private router: Router
    ) {}

  calenderPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
  ngOnInit() {

    firebase.initializeApp(environment.firebase);

    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0);
    });
}

  signInWithGoogle(): void {
    const socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        this.apiService.user.login(userData.idToken)
        .then((user) => {

        })
        .catch((err) => {
        })

      }
    );
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }



}
