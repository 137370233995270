<h2>User Home</h2>
<div *ngIf="userAuthService.user">
<p>{{userAuthService.user.firstName}} {{userAuthService.user.lastName}}
  <br>
  {{userAuthService.user.email}}
</p>
</div>

<button (click)="signOut()">Sign Out</button>

